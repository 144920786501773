import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useUserLogin, useUserMe, useUserMeWithChildren } from 'src/generated/endpoint';
import { Role } from 'src/generated/model';
import { useBrowserStorage } from 'src/hooks/useBrowserStorage';
import { useLogoutOnIdle } from 'src/hooks/useLogoutOnIdle';
import { RN } from 'src/lib/rn';
import { childState, isStayLoggedInState, meState, tokenState, twoFactorState } from 'src/store';
import { errorType } from 'src/types';
import { useLogout } from 'src/util/hooks';
import { createContainer } from './createContainer';

export function userHook() {
  const logout = useLogout();
  const [me, setMe] = useRecoilState(meState);
  const isStayLoggedIn = useRecoilValue(isStayLoggedInState);
  const { setStorage, getStorage } = useBrowserStorage();

  const setChild = useSetRecoilState(childState);
  const [token, setToken] = useRecoilState(tokenState);
  const setTwoFactorState = useSetRecoilState(twoFactorState);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorCode, setErrorCode] = useState('');

  // 로그인 시 자동로그인 체크하지 않으면 30분 후 자동 로그아웃
  useLogoutOnIdle(logout, isStayLoggedIn, 30);

  const {
    data: meData,
    refetch: refetchMe,
    isLoading: isMeLoading,
  } = useUserMe({
    query: {
      queryKey: ['me'],
      onSuccess: (res) => {
        setMe(res);
        if (!res.school.enhancedSecurity && (getStorage('two-factor') === null || getStorage('two-factor') === 'false')) {
          setStorage('two-factor', 'true');
          setTwoFactorState('true');
        }
        if (res.role === Role.PARENT) {
          refetchMeWithChildren();
        }
        const tagValue = {
          schoolId: res?.schoolId,
          role: res?.role,
        };
        RN.flareLane('setUserId', res?.email);
        RN.flareLane('setTags', tagValue);
      },
      enabled: !!token,
    },
  });

  const { refetch: refetchMeWithChildren } = useUserMeWithChildren({
    query: {
      enabled: !!meData,
      onSuccess: (parent) => {
        if (meData?.role !== Role.PARENT) {
          return;
        }
        const localChildId = +(localStorage.getItem('child-user-id') || '0');
        const _child = !localChildId
          ? parent?.children?.[0]
          : parent?.children?.find((child) => child.id === localChildId);

        if (_child?.id) {
          localStorage.setItem('child-user-id', JSON.stringify(_child.id));
        }
        setChild(_child);
      },
    },
  });

  const { mutate: loginMutation } = useUserLogin({
    mutation: {
      onSuccess: (res) => {
        if (!res.token) {
          throw new Error('No token');
        }
        setToken(res.token);
        setStorage('token', res.token);
      },
      onError: (err) => {
        const errorMsg: errorType | undefined = err?.response?.data as unknown as errorType;

        setErrorMessage(errorMsg?.message || '일시적 오류 입니다. 잠시 후 다시 시도해주세요.');
        setErrorCode(errorMsg?.code || '');
      },
    },
  });

  const handleLogin = (email: string, password: string) => {
    loginMutation({ data: { email, password } });
  };

  useEffect(() => {
    if (!me && token) {
      refetchMe();
    }
  }, []);

  return {
    me,
    refetchMe,
    isMeLoading,
    handleLogin,
    errorMessage,
    errorCode,
  };
}

export const UserContainer = createContainer(userHook);
