import { useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Label, Section } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { useParentManagementGetParentInfo } from 'src/generated/endpoint';
import { useLanguage } from 'src/hooks/useLanguage';
import { AdminContext } from '../AdminMainPage';

export function ParentDetailsPage() {
  const { year } = useContext(AdminContext);
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);
  const { t } = useLanguage();

  const { data: parent } = useParentManagementGetParentInfo(id, { year });

  return (
    <>
      <Admin.Section>
        <Admin.H2>{t('guardian_information')}</Admin.H2>
        <div className="flex items-center justify-between">
          <div className="flex gap-2">
            <Link children={t('edit')} to={`${id}/edit`} className="button-sm outlined-gray" />
          </div>
          <div className="flex gap-2"></div>
        </div>
        <Admin.Card className="grid h-[270px] grid-cols-3 divide-x">
          <Admin.Cell className="grid grid-rows-3">
            <div>
              <Label.Text children={t('name')} />
              <p className="mt-2">{parent?.name}</p>
            </div>
            <div>
              <Label.Text children={t('email')} />
              <p className="mt-2">{parent?.email}</p>
            </div>
            <div>
              <Label.Text children={t('phone_number')} />
              <p className="mt-2">{parent?.phone}</p>
            </div>
          </Admin.Cell>
          <Admin.Cell></Admin.Cell>
          <Admin.Cell></Admin.Cell>
        </Admin.Card>
      </Admin.Section>

      <Section>
        <Admin.H2>{t('student')}</Admin.H2>
        <Admin.Table>
          <Admin.TableHead>
            <Admin.TableRow>
              <Admin.TableHCell children={t('name')} />
              <Admin.TableHCell children={t('grade_class')} />
              <Admin.TableHCell children={t('attendance_number')} />
            </Admin.TableRow>
          </Admin.TableHead>
          <Admin.TableBody>
            {parent?.children.map((child) => (
              <Admin.TableRow key={child.childrenId}>
                <Admin.TableCell children={child.childrenName} />
                <Admin.TableCell children={child.childrenKlassGroupName} />
                <Admin.TableCell children={child.childrenStudentNumber} />
              </Admin.TableRow>
            ))}
          </Admin.TableBody>
        </Admin.Table>
      </Section>
    </>
  );
}
