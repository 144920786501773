/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type OutingStatus = (typeof OutingStatus)[keyof typeof OutingStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutingStatus = {
  RETURNED: 'RETURNED',
  BEFORE_TEACHER_APPROVAL: 'BEFORE_TEACHER_APPROVAL',
  PROCESSED: 'PROCESSED',
  DELETE_APPEAL: 'DELETE_APPEAL',
} as const;
