import { useQuery } from 'react-query';
import {
  getStudentExamScoreGetStudentExamScoresQueryKey,
  studentExamScoreGetStudentExamScores,
} from 'src/generated/endpoint';

export function useStudentSemetsersScore(userId: number) {
  const queryKey = getStudentExamScoreGetStudentExamScoresQueryKey(userId);

  const { data, isLoading } = useQuery(
    queryKey,
    ({ signal }) => studentExamScoreGetStudentExamScores(userId, {}, signal),
    { enabled: !!userId },
  );
  const scores = data?.exams_scores;

  return {
    scores,
    isLoading,
  };
}
