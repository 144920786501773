import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { ReactComponent as FileUploadImg } from 'src/assets/svg/upload-image.svg';
import { useLanguage } from 'src/hooks/useLanguage';

export interface ImageUploadProps extends InputHTMLAttributes<HTMLInputElement> {}

export function ImageUpload({ children, className, ...props }: ImageUploadProps) {
  const { t } = useLanguage();
  return (
    <label className={clsx('image-upload', className)}>
      {children || (
        <>
          <FileUploadImg />
          <p className="text-sm text-brand-1">{t('select_image_or_pdf')}</p>
        </>
      )}
      <input type="file" accept=".png, .jpeg, .jpg" className="hidden" {...props} />
    </label>
  );
}
