import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFieldtripsGetFieldtripsByTeacher, useUserMe } from 'src/generated/endpoint';
import { FieldtripStatus, Role } from 'src/generated/model';
import { MonthAgo, getEndDate, getStartDate, makeDateToString } from 'src/util/time';
import { UserContainer } from './user';

export function useTeacherFieldtripNotice() {
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  const [startDate, setStartDate] = useState(makeDateToString(MonthAgo(new Date())));
  const [endDate, setEndDate] = useState(makeDateToString(new Date()));
  const [page, setPage] = useState(Number(params.get('page') ?? '1'));
  const limit = Number(params.get('limit') ?? '1000') || 1000;
  const studentName = params.get('username') || '';
  const { me } = UserContainer.useContext();

  const { data, error } = useFieldtripsGetFieldtripsByTeacher({
    page,
    limit,
    fieldtripStatus: FieldtripStatus.PROCESSED,
    ...(studentName && { username: studentName }),
    startDate: getStartDate(startDate),
    endDate: getEndDate(endDate),
  });

  useEffect(() => {
    setPage(Number(params.get('page') ?? '1'));
  }, [params]);

  // 조회 권한 여부
  const { data: teacherData } = useUserMe({});

  let isViewAuth = true;
  if (teacherData?.role === Role.SECURITY) {
    isViewAuth = false;
  }

  // 승인 권한 여부
  const isApprovalAuth = true;
  // let isApprovalAuth = teacherData ? approvalLine?.includes(teacherData.role) : false;

  // if (isApprovalAuth === true && teacherData?.role === Role.TEACHER) {
  //   isApprovalAuth = teacherData?.klassGroupId !== undefined ? true : false;
  // }

  return {
    error,
    params,
    startDate,
    endDate,
    data,
    limit,
    page,
    isViewAuth,
    isApprovalAuth,

    // set state
    setPage,
    setStartDate,
    setEndDate,
  };
}
