/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type Role = (typeof Role)[keyof typeof Role];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Role = {
  ADMIN: 'ADMIN',
  TEACHER: 'TEACHER',
  USER: 'USER',
  PRE_HEAD: 'PRE_HEAD',
  HEAD: 'HEAD',
  PRE_PRINCIPAL: 'PRE_PRINCIPAL',
  PRINCIPAL: 'PRINCIPAL',
  VICE_PRINCIPAL: 'VICE_PRINCIPAL',
  HEAD_PRINCIPAL: 'HEAD_PRINCIPAL',
  SECURITY: 'SECURITY',
  PARENT: 'PARENT',
  STAFF: 'STAFF',
  GUEST_LECTURER: 'GUEST_LECTURER',
} as const;
