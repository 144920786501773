import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { studentManagementAdvancedStudent } from 'src/generated/endpoint';
import { RequestAdvancedStudentDto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { AdminContext } from '../AdminMainPage';

export function StudentBatchAdvancePage() {
  const { goBack } = useHistory();
  const { year } = useContext(AdminContext);
  const { t } = useLanguage();
  const [items, setItems] = useState<RequestAdvancedStudentDto[]>([]);

  async function readFile(file: File) {
    const [, ...rows] = await readXlsxFile(file);
    const items = rows.map(([newGrade, newKlass, newStudentNumber, name, oldGrade, oldKlass, oldStudentNumber]) => {
      return { newGrade, newKlass, newStudentNumber, name, oldGrade, oldKlass, oldStudentNumber };
    });
    setItems(items as any);
  }

  async function update() {
    if (items.length === 0) return;
    if (!confirm(`항목 ${items.length}개를 업데이트 하시겠습니까?`)) return;
    const promises = items.map((item) =>
      studentManagementAdvancedStudent({
        ...item,
        newGrade: Number(item.newGrade),
        newKlass: Number(item.newKlass),
        newStudentNumber: Number(item.newStudentNumber),
        oldGrade: Number(item.oldGrade),
        oldKlass: Number(item.oldKlass),
        oldStudentNumber: Number(item.oldStudentNumber),
        adventYear: year,
      }),
    );
    await Promise.all(promises);
    goBack();
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2 className="mb-4">{t('bulk_promotion_students')}</Admin.H2>

      <div className="flex gap-2">
        <a
          children={t('download_form')}
          href="https://kr.object.gov-ncloudstorage.com/superschool/storage/%ED%95%99%EC%83%9D%EC%A7%84%EA%B8%89%EC%A0%95%EB%B3%B4.xlsx"
          className="button-sm outlined-gray"
        />
        <label className="button-sm outlined-gray cursor-pointer">
          <p>{t('select_excel_file')}</p>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => e.target.files?.[0] && readFile(e.target.files[0])}
            className="sr-only"
          />
        </label>
        <Button.sm
          children={t('bulk_promotion')}
          disabled={items.length === 0}
          onClick={update}
          className="outlined-gray"
        />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell children={t('promotion_grade')} />
            <Admin.TableHCell children={t('promotion_class')} />
            <Admin.TableHCell children={t('promotion_number')} />
            <Admin.TableHCell children={t('name')} />
            <Admin.TableHCell children={t('previous_grade')} />
            <Admin.TableHCell children={t('previous_class')} />
            <Admin.TableHCell children={t('previous_number')} />
            <Admin.TableHCell />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {items.map((item, i) => (
            <Admin.TableRow key={i}>
              <Admin.TableCell children={item.newGrade} />
              <Admin.TableCell children={item.newKlass} />
              <Admin.TableCell children={item.newStudentNumber} />
              <Admin.TableCell children={item.name} />
              <Admin.TableCell children={item.oldGrade} />
              <Admin.TableCell children={item.oldKlass} />
              <Admin.TableCell children={item.oldStudentNumber} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>
    </Admin.Section>
  );
}
