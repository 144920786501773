import { useCanteenCreateOrUpdate } from 'src/generated/endpoint';
import { RequestUpsertCanteenDto } from 'src/generated/model';

export function useTeacherCanteenSubmit(refetch: () => void) {
  const { mutateAsync: upsertCanteen } = useCanteenCreateOrUpdate({
    mutation: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  const handleCanteenUpsert = (data: RequestUpsertCanteenDto) => {
    return upsertCanteen({ data });
  };

  return {
    handleCanteenUpsert,
  };
}
