import { PropsWithChildren } from 'react';
import { CloseButton } from 'src/components/common';

interface SuperModalProps {
  modalOpen: boolean;
  setModalClose: () => void;
  width?: string;
  ablePropragation?: boolean;
}

export function SuperModal({
  modalOpen,
  setModalClose,
  children,
  width = 'w-80',
  ablePropragation = false,
}: PropsWithChildren<SuperModalProps>) {
  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-littleblack ${
        !modalOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className={`${width} relative rounded-lg bg-white opacity-100`}>
        <div className="absolute right-3 top-3">
          <CloseButton onClick={() => setModalClose()} />
        </div>
        {children}
      </div>
    </div>
  );
}
