import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';

export interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onSearch?: () => void;
}

export function SearchInput({ onSearch, className, ...props }: SearchInputProps) {
  return (
    <input
      className={clsx('search-input', className)}
      onKeyDown={(e) => e.key === 'Enter' && onSearch?.()}
      {...props}
    />
  );
}
