export const forbiddenWords = [
  '어학',
  '연수',
  '해외',
  '봉사',
  '교환학생',
  '자격증',
  '논문',
  '소논문',
  '공동저자',
  '출간',
  '모의고사',
  '전국연합평가',
  '인증',
  '특허',
  '특허권',
  '출원',
  '취득',
  '영재',
  '대회',
  '수상',
  '우수상',
  '최우수상',
  '대상',
  '금상',
  '은상',
  '동상',
  '장려상',
  '1급',
  '2급',
  '3급',
  '1등',
  '2등',
  '3등',
  '제1',
  '제2',
  '제3',
  '장학생',
  '장학금',
  '시험',
  '기능사',
  '산업기사',
  '시험기술사',
  '구글',
  '네이버',
  '다음',
  '카카오톡',
  '인스타그램',
  '페이스북',
  '틱톡',
  '유튜브',
  '애플',
  '삼성',
  '네이버',
  '온라인클래스',
  '클래스룸',
  '유튜버',
  '개더타운',
  '이프랜드',
  '패틀렛',
  '띵커벨',
  '커리어넷',
  '메이저맵',
  '미리캔버스',
  '망고보드',
  '비트코인',
  '반크',
  '아이폰',
  'MOOC',
  'KOCW',
  'TED',
  'UN',
  'EU',
  'WHO',
  'OECD',
  'KTX',
  'SRT',
  'CCD',
  'PPT',
  'DNA',
  'POPS',
  'PD',
  'IT',
  'SNS',
  'TV',
  'CEO',
  '엄마',
  '어머니',
  '아빠',
  '아버지',
  '누나',
  '형',
  '대학',
  '대학교',
  '고등학교',
  '중학교',
  '초등학교',
  '외고',
  '과고',
  '서울대',
  '연세대',
  '고려대',
  '서강대',
  '성균관대',
  '한양대',
  '중앙대',
  '경희대',
  '외국어대',
  '서울시립대',
  '이화여대',
  '숙명여대',
  '동덕여대',
  '덕성여대',
  '서울여대',
  '건국대',
  '동국대',
  '단국대',
  '홍익대',
  '한국체대',
  '서울예대',
  '서울',
  '경기',
  '부산',
  '인천',
  '대전',
  '대구',
  '부산',
  '광주',
];
