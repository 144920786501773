import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useParams } from 'react-router-dom';
import Viewer from 'react-viewer';
import { ErrorBlank, SuperModal } from 'src/components';
import { Badge, Section } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { PdfCard } from 'src/components/common/PdfCard';
import { PdfViewer } from 'src/components/common/PdfViewer';
import { Time } from 'src/components/common/Time';
import { Constants } from 'src/constants';
import { useTeacherActivityDetail } from 'src/container/teacher-activity-detail';
import { getFileNameFromUrl } from 'src/util/file';
import { ActivityAddPage } from './ActivityAdd';

interface ActivityDetailPageProps {
  isUpdateState: boolean;
  setUpdateState: (b: boolean) => void;
  refetch: () => void;
}

export function ActivityDetailPage({ isUpdateState, setUpdateState, refetch }: ActivityDetailPageProps) {
  const { id } = useParams<{ id: string }>();
  const { activity, images, Pdfs, documents, viewerImages, errorMessage, handleActivityDelete } =
    useTeacherActivityDetail(+id, refetch);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [hasPdfModalOpen, setPdfModalOpen] = useState(false);
  const [focusPdfFile, setFocusPdfFile] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);

  if (errorMessage) return <ErrorBlank />;

  if (isUpdateState) {
    return (
      <ActivityAddPage
        activityId={+id}
        refetch={() => {
          refetch();
          setUpdateState(false);
        }}
      />
    );
  }

  return (
    <div className="m-5 h-screen-8 overflow-y-scroll rounded-lg border bg-white p-5 md:h-screen-3">
      <SuperModal modalOpen={isDeleteModalOpen} setModalClose={() => setDeleteModalOpen(false)} width="w-max">
        <Section className="mt-7">
          <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">
            정말 해당 과제를 삭제하시겠습니까?
          </div>
          <Button.lg children="삭제하기" onClick={() => handleActivityDelete()} className="filled-primary" />
        </Section>
      </SuperModal>
      <div className="flex justify-between">
        <Badge children={activity?.subject} className="bg-light_orange text-brand-1" />
        <div className="font-base flex cursor-pointer space-x-4 text-gray-500">
          <div className="text-gray-700" onClick={() => setUpdateState(true)}>
            수정
          </div>
          <div className="cursor-pointer text-red-400" onClick={() => setDeleteModalOpen(true)}>
            삭제
          </div>
        </div>
      </div>
      <div className="space-y-0.5">
        <div className="mt-2 text-lg font-semibold">{activity?.title}</div>
        {activity?.endDate && (
          <div className="flex gap-1 text-sm font-normal text-red-400">
            <span className="font-semibold">마감기한</span>
            <Time date={activity.endDate} className="text-inherit" />
            <span>까지</span>
          </div>
        )}
      </div>
      <div className="mt-3 flex flex-wrap">
        {activity?.groupActivities?.map((groupActivity) => (
          <span
            key={groupActivity.id}
            className="mb-2 mr-2 rounded-full border border-gray-400 px-3 py-2 text-sm font-semibold text-gray-500"
          >
            {groupActivity?.group?.name}
          </span>
        ))}
      </div>

      {activity?.isRecord && (
        <div className="m-2 rounded-lg bg-light_orange  px-2 py-1 text-sm text-gray-500">
          <div className="text-sm">공통문구 : {activity.commonText} </div>
          <div className="text-sm">활동요약 : {activity.explainText} </div>
        </div>
      )}

      {activity?.type === 'SURVEY' ? (
        <>
          {/* <SurveyComponent content={activity?.content || '{}'} setContent={(data) => console.log(data)} btnHide /> */}
        </>
      ) : (
        <div>
          <Section className="px-0">
            <div className="grid w-full grid-flow-row grid-cols-3 gap-2">
              {images?.map((image: string, i: number) => (
                <div
                  key={i}
                  onClick={() => {
                    setActiveIndex(i);
                    setImagesModalOpen(true);
                  }}
                  className="w-full"
                >
                  <div className="aspect-5/3 rounded bg-gray-50">
                    <LazyLoadImage
                      src={`${Constants.imageUrl}${image}`}
                      alt=""
                      loading="lazy"
                      className="h-full w-full rounded object-cover"
                    />
                  </div>
                </div>
              ))}
              {Pdfs?.map((pdfFile: string, i: number) => {
                return (
                  <>
                    <div key={pdfFile}>
                      <div className="w-full">
                        <div className="relative aspect-5/3 rounded bg-gray-50">
                          <PdfCard
                            fileUrl={`${Constants.imageUrl}${pdfFile}`}
                            visibleButton
                            onClick={() => {
                              setFocusPdfFile(`${Constants.imageUrl}${pdfFile}`);
                              setPdfModalOpen(true);
                            }}
                          ></PdfCard>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div>
              {documents?.map((fileUrl: string, index) => (
                <div
                  key={index}
                  className="relative m-2 flex items-center justify-between overflow-x-hidden bg-white p-2"
                >
                  <span>{getFileNameFromUrl(fileUrl)}</span>
                  <div className="min-w-max bg-white px-2 text-lightpurple-4">
                    <a
                      href={`${Constants.imageUrl}${fileUrl}`}
                      target="_blank"
                      rel="noreferrer"
                      download={getFileNameFromUrl(fileUrl)}
                    >
                      Download
                    </a>
                  </div>
                </div>
              ))}
            </div>

            <div className="feedback_space mt-8 whitespace-pre-line break-all text-base text-gray-500">
              <Linkify>{activity?.content}</Linkify>
            </div>

            <div className="absolute">
              <Viewer
                visible={hasImagesModalOpen}
                rotatable
                noImgDetails
                scalable={false}
                images={viewerImages}
                onClose={() => setImagesModalOpen(false)}
                activeIndex={activeIndex}
              />
            </div>
            <div className="absolute">
              <PdfViewer isOpen={hasPdfModalOpen} fileUrl={focusPdfFile} onClose={() => setPdfModalOpen(false)} />
            </div>
          </Section>
        </div>
      )}
    </div>
  );
}
