import { useState } from 'react';
import { Constants } from 'src/constants';
import { UserContainer } from 'src/container/user';
import { ResponseCoulselingDetailDto } from 'src/generated/model';
import { SuperModal } from '../SuperModal';
import { Button } from '../common/Button';
import { Time } from '../common/Time';
import WAVPlayer from '../rec/wavPlayer';

interface StudentCoulselingProps {
  data: ResponseCoulselingDetailDto;
  deleteCoulseling: (id: number) => void;
}

export default function CoulselingDetailCard({ data, deleteCoulseling }: StudentCoulselingProps) {
  const { me } = UserContainer.useContext();
  const [alertDelete, setAlertDelete] = useState(false);

  let categoryColorClass = '';
  switch (data.category) {
    case '진로':
      categoryColorClass = 'bg-coulselingCategory-0';
      break;
    case '교우관계':
      categoryColorClass = 'bg-coulselingCategory-1';
      break;
    case '기타':
      categoryColorClass = 'bg-coulselingCategory-2';
      break;
    case '학교폭력':
      categoryColorClass = 'bg-coulselingCategory-3';
      break;
    default:
      categoryColorClass = 'bg-lightgreen-2';
  }
  return (
    <div className={`p-4 ${categoryColorClass} relative flex h-[308px] flex-col rounded-md md:h-64`}>
      <div className="flex justify-between text-sm">
        <div className="flex items-center gap-1">
          <h6 className="font-bold">{data.category}</h6>
          <p>| 상담자 : {data.coulselorName}</p>
        </div>
        <div>
          <Time format="yyyy.MM.dd" date={data.coulselingAt} />
        </div>
      </div>
      <h5 className="font-bold">상담내용</h5>
      <div className="h-[200px] overflow-y-auto whitespace-pre-line break-all text-sm">
        {data.content}
        <br />
        {data.transcript}
      </div>
      {data.writerId === me?.id && (
        <div className="flex justify-between">
          <div>
            {data.voiceFiles && data.voiceFiles.length > 0 && (
              <WAVPlayer initialUrl={`${Constants.imageUrl}${data.voiceFiles[0]}`} />
            )}
          </div>
          <button
            onClick={() => setAlertDelete(true)}
            className="w-15 rounded-xl border-2 border-red-500 bg-white px-3 py-0.5 text-sm font-bold text-red-500 hover:bg-red-500 hover:text-white"
          >
            삭제
          </button>
        </div>
      )}
      <SuperModal modalOpen={alertDelete} setModalClose={() => setAlertDelete(false)} width="w-max">
        <div className="px-12 py-6">
          <div className="mb-6 w-full text-center text-lg font-bold text-gray-900">상담 기록을 삭제하시겠습니까?</div>
          <Button.lg
            children="삭제하기"
            onClick={() => {
              deleteCoulseling(data.id);
              setAlertDelete(false);
            }}
            className="filled-primary w-full"
          />
        </div>
      </SuperModal>
    </div>
  );
}
