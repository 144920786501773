import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  useStudentActivityFindByUserId,
  useSummariesCreate,
  useSummariesFindByStudentGroupId,
} from 'src/generated/endpoint';
import { StudentGroup, Summary } from 'src/generated/model';
import { meState } from 'src/store';

type Props = {
  userId: number;
  groupId: number;
  studentGroups?: StudentGroup[];
};

export function useTeacherRecordDetail({ userId, groupId, studentGroups }: Props) {
  const me = useRecoilValue(meState);
  const [errorText, setErrorText] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [recordSummary, setRecordSummary] = useState('');
  const [summaries, setSummaries] = useState<Summary[]>();
  const selectedStudentGroup = studentGroups?.filter((sg) => sg.user?.id === userId)[0];
  const selectedUser = selectedStudentGroup?.user;

  const { data: studentActivities } = useStudentActivityFindByUserId(
    {
      userId,
    },
    {
      query: {
        enabled: !!(userId && groupId),
        keepPreviousData: true,
      },
    },
  );

  const { data } = useSummariesFindByStudentGroupId(
    { studentGroupId: selectedStudentGroup?.id ?? 0 },
    {
      query: {
        enabled: !!selectedStudentGroup?.id,
        onSuccess: (res) => {
          setSummaries(res);
        },
      },
    },
  );

  const { mutate: createRecordSummaryMutate, isLoading: isCreateSummaryLoading } = useSummariesCreate({
    mutation: {
      onSuccess: (res) => {
        setErrorText('');
      },
      onError: (err) => {
        setErrorText(err.message);
      },
    },
  });

  const createRecordSummary = () => {
    if (!selectedStudentGroup?.id || !me?.id) return;

    createRecordSummaryMutate({
      data: {
        content: recordSummary,
        subject: selectedSubject,
        studentGroupId: selectedStudentGroup.id,
      },
    });
  };

  // const { mutate: updateRecordSummaryMutate } = useSummariesUpdate({
  //   mutation: {
  //     onSuccess: () => {
  //       setErrorText('');
  //       refetchSummaries();
  //     },
  //     onError: (err) => {
  //       setErrorText(err.message);
  //     },
  //   },
  // });

  // const updateRecordSummary = () => {
  //   if (!summaryBySubject?.id) return;

  //   updateRecordSummaryMutate({
  //     id: summaryBySubject.id,
  //     data: {
  //       recordSummary,
  //     },
  //   });
  // };

  // const createOrUpdateRecordSummary = () => {
  //   return summaryBySubject ? updateRecordSummary() : createRecordSummary();
  // };

  const summaryBySubject = summaries?.find((s) => s.subject === selectedSubject);
  const subjects = [...new Set(studentActivities?.map((sa) => sa?.activity?.subject))];
  const isLoading = isCreateSummaryLoading;

  return {
    studentActivities,
    isLoading,
    subjects,
    errorText,
    selectedSubject,
    selectedStudentGroup,
    selectedUser,
    setSelectedSubject,
    recordSummary,
    setRecordSummary,
    createRecordSummary,
    //createOrUpdateRecordSummary,
    summaries,
  };
}
