import { RegisterOptions } from 'react-hook-form';

function minLength(value: number) {
  const message = `최소 ${value} 글자 이상 입력해주세요`;
  return { required: { value: value > 0, message }, minLength: { value, message } } as RegisterOptions;
}

function maxLength(value: number) {
  return { maxLength: { value, message: `최대 ${value} 글자를 넘을 수 없습니다` } } as RegisterOptions;
}

function length(min: number, max: number) {
  return { ...minLength(min), ...maxLength(max) } as RegisterOptions;
}

function min(value: number) {
  return { min: { value, message: `최소 ${value} 이상이어야 합니다` }, valueAsNumber: true } as RegisterOptions;
}

function max(value: number) {
  return { max: { value, message: `최대 ${value} 이하이어야 합니다` }, valueAsNumber: true } as RegisterOptions;
}

function minmax(minValue: number, maxValue: number) {
  return { ...min(minValue), ...max(maxValue) } as RegisterOptions;
}

export const form = { minLength, maxLength, length, min, max, minmax };
