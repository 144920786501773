/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type FieldtripType = (typeof FieldtripType)[keyof typeof FieldtripType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FieldtripType = {
  SUBURBS: 'SUBURBS',
  HOME: 'HOME',
} as const;
