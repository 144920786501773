export const Validator = {
  removeSpace: (value: string) => value.replace(/\s/g, ''),
  passwordRule: (value: string) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/.test(value),
  phoneNumberRule: (value: string) => /^010(?:\d{4})\d{4}$/.test(value),
  emailRule: (value: string) => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value),
  fileNameRule: (value: string) => /[%&?~+]/.test(value) === false,
  nameRule: (value: string) => /[~`!@#$%^&*()_+|<>?:/;'".,]/.test(value) || /[ ]/.test(value),
  onlyEngAndHan: (value: string) => /[^\uAC00-\uD7A3a-zA-Z]/g.test(value) === false,
};

export function removeControlCharacters(str: string) {
  // eslint-disable-next-line no-control-regex
  return str.replace(/[\u0000-\u001F\u007F-\u009F]/g, '');
}

export function jsonParseSafe<T>(jsonString: string | null, defaultValue: T): T {
  try {
    if (!jsonString || jsonString.trim() === '') {
      return defaultValue;
    }
    const jsonObj = JSON.parse(jsonString) as T;
    return jsonObj;
  } catch (error) {
    return defaultValue;
  }
}

export function isHTML(str: string) {
  const htmlRegex = /<\/?[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
}
