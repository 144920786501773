import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  to?: string;
}

export function IconButton({ to, className, ...props }: IconButtonProps) {
  const button = <button className={clsx('icon-button', className)} {...props} />;
  return to ? <Link to={to} children={button} /> : button;
}
