import { useState } from 'react';
import { useCommonUserSearch } from 'src/generated/endpoint';
import { ResponseGroupDto, ResponseSearchUserDto, Role } from 'src/generated/model';
import { GroupContainer } from './group';

export function useTeacherSearchUser(searchRole?: Role) {
  const [userDatas, setUserDatas] = useState<ResponseSearchUserDto[]>([]);
  const [klassName, setKlassName] = useState<string | undefined>();
  const [keyword, setKeyword] = useState<string | undefined>();

  const { allKlassGroups } = GroupContainer.useContext();

  const setGroupId = (groupId: number) => {
    const klass = allKlassGroups.find((item: ResponseGroupDto) => item?.id === groupId)?.name || '';

    setKlassName(klass);
  };

  const setSearchRole = (newSearchRole: Role) => {
    searchRole = newSearchRole;
  };

  const { data, isLoading } = useCommonUserSearch<ResponseSearchUserDto[]>(
    { keyword, klass: keyword ? undefined : klassName },
    {
      query: {
        enabled: !!keyword || !!klassName,
        onSuccess: (res) => {
          if (!res?.length) {
            setUserDatas([]);
            return;
          }

          if (searchRole === undefined) {
            setUserDatas(
              res.sort((a, b) => {
                return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
              }),
            );
          } else if (searchRole === Role.USER || searchRole === Role.PARENT) {
            setUserDatas(
              res
                .filter((el) => el.role === searchRole)
                .sort((a, b) => {
                  return a.sn && b.sn && a.sn < b.sn ? -1 : 1;
                }),
            );
          } else {
            setUserDatas(
              res
                .filter((el) => el.role !== Role.USER && el.role !== Role.PARENT)
                .sort((a, b) => {
                  return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                }),
            );
          }
        },
      },
    },
  );

  return {
    userDatas,
    setGroupId,
    klassName,
    setKlassName,
    setKeyword,
    setSearchRole,
    isLoading,
  };
}
