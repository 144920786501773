import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { Constants } from 'src/constants';
import { QueryKey } from 'src/constants/query-key';
import { useNoticesDelete, useNoticesFindOne } from 'src/generated/endpoint';
import { Routes } from 'src/routes';
import { isPdfFile } from 'src/util/file';

export function useTeacherNoticeDetail(noticeId?: number) {
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState('');

  const { data: notice, isLoading: isNoticeLoading } = useNoticesFindOne(noticeId as number, {
    query: {
      enabled: !!noticeId,
      queryKey: [...QueryKey.teacher.notice, noticeId],
      onError: () => {
        setErrorMessage('공지사항을 불러오는데 실패했습니다.');
      },
    },
  });

  const { mutate: deleteNotice } = useNoticesDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.teacher.noticeList);
        push(Routes.teacher.notice);
      },
      onError: () => {
        setErrorMessage('공지사항 삭제에 실패했습니다.');
      },
    },
  });

  const images = notice?.images.filter((image) => !isPdfFile(image)) || [];
  const Pdfs = notice?.images.filter((image) => isPdfFile(image)) || [];
  const files = notice?.files || [];

  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    if (isPdfFile(image) == false) {
      viewerImages.push({
        src: `${Constants.imageUrl}${image}`,
      });
    }
  }

  const handleNoticeDelete = () => {
    deleteNotice({ id: noticeId as number });
  };

  return {
    notice,
    isNoticeLoading,
    images,
    Pdfs,
    files,
    viewerImages,
    errorMessage,
    handleNoticeDelete,
  };
}
