import { useState } from 'react';
import { QueryKey } from 'src/constants/query-key';
import { useNoticesFindAll } from 'src/generated/endpoint';
import { createContainer } from './createContainer';

export function useTeacherNoticeHook() {
  const [pageIngo, setPageInfo] = useState({ page: 1, limit: 500 });
  const [category, setCategory] = useState<string>('전체');

  const {
    data: noticeList,
    isLoading: isNoticeListLoading,
    isError: isNoticeListError,
  } = useNoticesFindAll(
    { ...pageIngo },
    {
      query: {
        queryKey: [...QueryKey.teacher.noticeList, pageIngo],
      },
    },
  );

  const filteredNoticeList =
    category === '전체' ? noticeList?.items : noticeList?.items.filter((el) => el.category === category);

  const unReadNoticeList = noticeList?.unreadIdList;

  return {
    filteredNoticeList,
    category,
    isNoticeListLoading,
    isNoticeListError,
    unReadNoticeList,
    setCategory,
  };
}

export const TeacherNoticeContainer = createContainer(useTeacherNoticeHook);
