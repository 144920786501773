import { addYears, format } from 'date-fns';
import { useState } from 'react';
import { useFieldtripsUpdateByTeacher, useSchedulesFindRejectSchedule } from 'src/generated/endpoint';
import { Fieldtrip, FieldtripType } from 'src/generated/model';
import { errorType } from 'src/types';
import { usePrevious } from 'src/util/hooks';
import { toLocaleDateFormatString } from 'src/util/time';

export function useTeacherFieldtripUpdate({
  fieldtrip,
  updateReason,
  setReadState,
  fieldtripType,
  wholeDayPeriod,
  startHalf,
  startPeriodS,
  endHalf,
  endPeriodE,
}: {
  fieldtrip: Fieldtrip;
  updateReason: string;
  setReadState: () => void;
  fieldtripType: string;
  wholeDayPeriod: string;
  startHalf: boolean;
  startPeriodS: number;
  endHalf: boolean;
  endPeriodE: number;
}) {
  const [content, setContent] = useState(fieldtrip?.content || '');
  const [accommodation, setAccommodation] = useState(
    fieldtrip.type === 'HOME' ? '자택' : fieldtrip?.accommodation || '',
  );
  const [overseas, setOverseas] = useState(fieldtrip?.overseas || false);
  const [destination, setDestination] = useState(fieldtrip?.destination);
  const [guideName, setGuideName] = useState(fieldtrip?.guideName || '');
  const [guidePhone, setGuidePhone] = useState(fieldtrip?.guidePhone || '');
  const [reportedAt, setReportedAt] = useState(fieldtrip?.reportedAt);
  const [startAt, setStartAt] = useState(
    fieldtrip?.startAt ? toLocaleDateFormatString(new Date(fieldtrip?.startAt)) : '',
  );
  const [endAt, setEndAt] = useState(fieldtrip?.endAt ? toLocaleDateFormatString(new Date(fieldtrip?.endAt)) : '');
  const [usedDays, setUsedDays] = useState(fieldtrip?.usedDays || 0);
  const prevUsedDays = usePrevious(usedDays);
  const [relationship, setRelationship] = useState(fieldtrip?.relationship || '');
  const [relationshipText, setRelationshipText] = useState(fieldtrip?.relationship || '');
  const [purpose, setPurpose] = useState(fieldtrip?.purpose);
  const [homePlan, setHomePlan] = useState<any>(
    fieldtrip && fieldtrip?.type === 'HOME' ? JSON.parse(fieldtrip?.content || '[]') : [],
  );
  const [errorMessage, setErrorMessage] = useState('');

  const {
    data: cannotSchedules,
    error,
    isLoading: isGetRejectScheduleLoading,
  } = useSchedulesFindRejectSchedule({
    startDate: startAt
      ? format(new Date(fieldtrip?.startAt).setDate(1), 'yyyy-MM-dd')
      : format(new Date().setDate(1), 'yyyy-MM-dd'),
    endDate: format(addYears(new Date(), 1), 'yyyy-MM-dd'),
  });

  const { mutate, isLoading } = useFieldtripsUpdateByTeacher({
    mutation: {
      onSuccess: () => {
        alert('체험학습 신청서를 수정하였습니다.');
        setReadState();
      },
      onError: (error) => {
        const errorMsg: errorType | undefined = error?.response?.data
          ? (error?.response?.data as errorType)
          : undefined;

        setErrorMessage(errorMsg?.message || '일시적 오류 입니다. 잠시 후 다시 시도해주세요.');
      },
    },
  });

  const updateFieldtripByTeacher = () => {
    mutate({
      id: fieldtrip?.id,
      data: {
        content: fieldtrip.type === FieldtripType.HOME ? JSON.stringify(homePlan) : content,
        reportedAt,
        startAt,
        endAt,
        usedDays,
        wholeDayPeriod,
        startPeriodS: startHalf ? startPeriodS : 0,
        endPeriodE: endHalf ? endPeriodE : 0,
        accommodation,
        destination,
        overseas,
        guideName,
        guidePhone,
        relationship: relationshipText,
        purpose,
        type: fieldtrip.type,
        form: fieldtripType,
        updateReason,
      },
    });
  };

  return {
    content,
    purpose,
    accommodation,
    destination,
    guideName,
    guidePhone,
    relationship,
    relationshipText,
    homePlan,
    prevUsedDays,
    usedDays,
    startAt,
    endAt,
    reportedAt,
    overseas,
    setOverseas,
    setReportedAt,
    setContent,
    setDestination,
    setPurpose,
    setAccommodation,
    setGuideName,
    setGuidePhone,
    setRelationship,
    setRelationshipText,
    setHomePlan,
    errorMessage,
    updateFieldtripByTeacher,
    isLoading,
    setEndAt,
    setStartAt,
    setUsedDays,
    cannotSchedules,
  };
}
