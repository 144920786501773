import JSZip from 'jszip';
import { Constants } from 'src/constants';
import { getFileNameFromUrl } from 'src/util/file';

/**
 * @param files - 압축해서 저장할 파일
 * @param title - 파일 저장명
 */

export const handleBulkDownload = async (files: string[], title: string) => {
  const zip = new JSZip();
  const folder = zip.folder(title);

  if (!folder) {
    console.error('Failed to create zip folder');
    return;
  }

  const filePromises = files.map((file) => {
    const url = `${Constants.imageUrl}${file}`;
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        folder.file(getFileNameFromUrl(file), blob);
      });
  });

  await Promise.all(filePromises);

  zip.generateAsync({ type: 'blob' }).then((content) => {
    const url = URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${title}.zip`;
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);
  });
};
