import { forwardRef } from 'react';
import { Absent } from 'src/generated/model';
import { makeStartEndToString } from 'src/util/time';
import { Td } from '../Td';
import { useSignedUrl } from 'src/lib/query';

interface TeacherConfirmPaperProps {
  absent: Absent;
}

export const TeacherConfirmPaper = forwardRef((props: TeacherConfirmPaperProps, ref: any) => {
  const { absent } = props;
  const { data: approver1Signature } = useSignedUrl(absent?.approver1Signature);

  return (
    <div ref={ref} className="w-full bg-white  md:h-[1100px]">
      <div className="flex  w-full flex-col space-y-6 p-5  md:p-20 md:pt-[87px]">
        <div className="mt-20 w-full min-w-max text-center text-xl font-bold md:text-3xl">담임교사 확인서</div>
        <div className="flex justify-between">
          <table className="w-full text-center text-sm font-bold md:text-base"></table>
          <div className="w-full text-right text-gray-600">
            학번:{'  '}
            {absent?.studentGradeKlass} {absent?.studentNumber}번 {'  '} <br />
            이름:{'  '}
            {absent?.student?.name}
          </div>
        </div>
        <div>
          <table className="w-full text-center text-sm font-bold md:text-base">
            <tr>
              {/* <Td innerClassName="md:w-[120px]">신고 유형</Td> */}
              <Td innerClassName="">신고 유형</Td>
              <Td>{absent?.reportType}</Td>
            </tr>
            <tr>
              <Td innerClassName="">상세 내용</Td>
              <Td>{absent?.description}</Td>
            </tr>
            <tr>
              <Td innerClassName="">기간</Td>
              <Td>
                {absent?.startAt &&
                  absent?.endAt &&
                  absent?.reportType &&
                  makeStartEndToString(absent.startAt, absent.endAt, absent.reportType)}
              </Td>
            </tr>
            <tr>
              <Td innerClassName="">신고사유</Td>
              <Td>{absent?.reason}</Td>
            </tr>
            <tr>
              <Td innerClassName="">담임교사 코멘트</Td>
              <Td innerClassName="text-left"> {absent?.teacherComment}</Td>
            </tr>
          </table>
          <div className="whitespace-pre-line pt-1 text-brand-1">
            *{absent?.student?.name} 학생이{' '}
            {absent?.startAt &&
              absent?.endAt &&
              absent?.reportType &&
              makeStartEndToString(absent.startAt, absent.endAt, absent.reportType)}{' '}
            동안 학교에{' '}
            {absent?.reportType === '지각' || absent?.reportType === '결과' || absent?.reportType === '조퇴'
              ? absent?.reportType
              : '미출석'}
            함을 확인하였습니다.
          </div>
        </div>
        <div>
          <div className="flex w-full items-center space-x-4">
            <div className="w-full min-w-max text-right text-gray-600">신고일:</div>
            <div className="w-2/5 min-w-max text-right text-lg font-bold text-gray-800">{absent?.reportedAt}</div>
            <div className="min-w-[50px]" />
          </div>

          <div className="flex w-full items-center space-x-4">
            <div className="w-full min-w-max text-right text-gray-600">선생님:</div>
            <div className="w-2/5 min-w-max text-right text-lg font-bold text-gray-800">{absent?.teacher?.name}</div>
            {approver1Signature ? (
              <img src={approver1Signature} alt="" crossOrigin="anonymous" className="m-auto w-[50px]" />
            ) : (
              <div className="h-[5px] min-w-[50px]" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
