import clsx from 'clsx';
import { useMemo } from 'react';
import { Link, Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Toast } from 'src/components/Toast';
import { Icon } from 'src/components/common/icons';
import { Role } from 'src/generated/model';
import { meState } from 'src/store';
import { useAuth } from 'src/util/hooks';
import { PermissionUtil } from 'src/util/permission';
import { AbsentAddPage } from './absent/AbsentAddPage';
import { AbsentDetailPage } from './absent/AbsentDetailPage';
import { AbsentPage } from './absent/AbsentPage';
import { ApplyPage } from './absent/ApplyPage';
import { ActivitySessionDetailPage } from './activityv3/ActivitySessionDetailPage';
import { ActivitySessionPage } from './activityv3/ActivitySessionPage';
import { ActivityV3Page } from './activityv3/ActivityV3Page';
import AnnouncementPage from './announcement/AnnouncementPage';
import { BoardDetailPage } from './board/BoardDetailPage';
import { CanteenPage } from './canteen/CanteenPage';
import { ChatListPage } from './chat/ChatListPage';
import { FieldtripAddPage } from './fieldtrip/FieldtripAddPage';
import { FieldtripApprovalPage } from './fieldtrip/FieldtripApprovalPage';
import { FieldtripDetailPage } from './fieldtrip/FieldtripDetailPage';
import { FieldtripHomeReportAddPage } from './fieldtrip/FieldtripHomeReportAddPage';
import { FieldtripNoticePage } from './fieldtrip/FieldtripNoticePage';
import { FieldtripPage } from './fieldtrip/FieldtripPage';
import { FieldtripResultDetailPage } from './fieldtrip/FieldtripResultDetailPage';
import { FieldtripSuburbsReportAddPage } from './fieldtrip/FieldtripSuburbsReportAddPage';
import { FieldtripSuburbsReportDetailPage } from './fieldtrip/FieldtripSuburbsReportDetailPage';
import { MyPage } from './mypage/MyPage';
import { NotificationSettingsPage } from './mypage/NotificationSettingsPage';
import { NewsletterDetailPage } from './newsletter/NewsletterDetailPage';
import { NoticeDetailPage } from './notice/NoticeDetailPage';
import { NoticePage } from './notice/NoticePage';
import { OutingAddPage } from './outing/OutingAddPage';
import { OutingDetailPage } from './outing/OutingDetailPage';
import { OutingPage } from './outing/OutingPage';

export function StudentMainPage() {
  const { replace } = useHistory();
  const me = useRecoilValue(meState);
  const { pathname } = useLocation();
  const { authenticated } = useAuth();

  if (PermissionUtil.isNotStudentNotParent(me?.role)) {
    replace('/teacher');
  } else {
    if (me?.role === Role.USER && me?.firstVisit) {
      replace('/first-login');
    }
  }

  const tabs = useMemo(
    () => [
      { path: '/student/activity', icon: Icon.Activity, name: '활동', hidden: me?.role === Role.PARENT },
      {
        path: '/student/apply',
        icon: Icon.Planner,
        name: '출결',
        extra: ['/student/outing', '/student/absent', '/student/fieldtrip'],
      },
      { path: '/student/canteen', icon: Icon.Home, name: '홈' },
      {
        path: '/student/notice',
        icon: Icon.Notice,
        name: '공지',
        extra: ['/student/board', '/student/newsletter'],
      },
      { path: '/student/mypage', icon: Icon.MoreVertical, name: '더보기', extra: ['/student/announcement'] },
    ],
    [me?.role, authenticated],
  );

  return (
    <div className="h-screen min-h-screen w-full min-w-full">
      <div className="scroll-box h-screen-4 w-full overflow-scroll">
        <Switch>
          <Route path="/student/notice/:id" component={NoticeDetailPage} />
          <Route path="/student/board/:id" component={BoardDetailPage} />
          <Route path="/student/newsletter/:id" component={NewsletterDetailPage} />
          <Route path="/student/fieldtrip/notice/:id" component={FieldtripNoticePage} />
          <Route path="/student/fieldtrip/result/:id" component={FieldtripResultDetailPage} />
          <Route path="/student/fieldtrip/add/report/suburbs/:id" component={FieldtripSuburbsReportAddPage} />
          <Route path="/student/fieldtrip/add/report/home/:id" component={FieldtripHomeReportAddPage} />
          <Route path="/student/fieldtrip/detail/report/suburbs" component={FieldtripSuburbsReportDetailPage} />
          <Route path="/student/fieldtrip/add/:type" component={FieldtripAddPage} />
          <Route path="/student/fieldtrip/approve/:id" component={FieldtripApprovalPage} />
          <Route path="/student/fieldtrip/:id" component={FieldtripDetailPage} />
          <Route path="/student/fieldtrip" component={FieldtripPage} />
          <Route path="/student/absent/add" component={AbsentAddPage} />
          <Route path="/student/absent/:id" component={AbsentDetailPage} />
          <Route path="/student/absent" component={AbsentPage} />
          <Route path="/student/apply" component={ApplyPage} />
          <Route path="/student/activity/:id/session/:asid" component={ActivitySessionDetailPage} />
          <Route path="/student/activity/:id" component={ActivitySessionPage} />
          <Route path="/student/activity" component={ActivityV3Page} />
          <Route path="/student/canteen" component={CanteenPage} />
          <Route path="/student/mypage" component={MyPage} />
          <Route path="/student/notice" component={NoticePage} />
          <Route path="/student/board" component={NoticePage} />
          <Route path="/student/newsletter" component={NoticePage} />
          <Route path="/student/outing/add" component={OutingAddPage} />
          <Route path="/student/outing/:id" component={OutingDetailPage} />
          <Route path="/student/outing" component={OutingPage} />
          <Route path="/student/chat" component={ChatListPage} />
          <Route path="/student/announcement" component={AnnouncementPage} />
          <Route path="/student/notification-settings" component={NotificationSettingsPage} />
          <Route path="/student">
            <Redirect to="/student/canteen" />
          </Route>
        </Switch>
      </div>

      <nav className="bottom-nav">
        {tabs.map((tab) => {
          const active = [tab.path, ...(tab.extra ?? [])].some((path) => pathname.startsWith(path));
          return tab.hidden ? null : (
            <Link key={tab.path} to={tab.path} className={clsx('bottom-nav-item', active && 'text-darkgray')}>
              <tab.icon className="stroke-current" />
              <span>{tab.name}</span>
            </Link>
          );
        })}
      </nav>

      <Toast />
    </div>
  );
}
