import i18n from 'i18next';
// 초기화 도와주는 함수
// React와 i18next를 연동하기 위한 부분
import { initReactI18next } from 'react-i18next';

import translationEn from '../locales/en/common.json';
import translationKo from '../locales/ko/common.json';

// 다국어 리소스 객체를 정의
const resources = {
  en: { translation: translationEn },
  ko: { translation: translationKo },
};

// 사용자의 브라우저 언어 설정을 확인하고, 설정이 없으면 기본값으로 사용할 언어를 결정
const userLanguage = window.navigator.language.split('-')[0]; // 'ko-KR' 같은 값을 'ko'로 변환

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem('language') || userLanguage || 'en',
  fallbackLng: 'ko',
  keySeparator: '.',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
});

export default i18n;
export const languages = ['en', 'ko'] as const;
export type Languages = (typeof languages)[number];
