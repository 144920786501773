import clsx from 'clsx';
import { InputHTMLAttributes } from 'react';
import { useLanguage } from 'src/hooks/useLanguage';

export interface FileUploadProps extends InputHTMLAttributes<HTMLInputElement> {}

export function FileUpload({ children, className, ...props }: FileUploadProps) {
  const { t } = useLanguage();
  return (
    <div className={clsx('file-upload', className)}>
      {children}
      <label className="flex h-12 cursor-pointer items-center justify-center space-x-1 text-brand-1">
        <span className="mb-1 text-2xl text-grey-3">+</span>
        <span className="text-sm">{t('select_file')}</span>
        <input type="file" multiple className="sr-only" {...props} />
      </label>
    </div>
  );
}
