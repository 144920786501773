import { useState } from 'react';
import { QueryKey } from 'src/constants/query-key';
import { useNewsLettersFindAll } from 'src/generated/endpoint';
import { Newsletter, NewsletterCategoryEnum } from 'src/generated/model';

export function useTeacherNewsletter() {
  const [pageIngo, setPageInfo] = useState({ page: 1, limit: 500 });
  const [category, setCategory] = useState<NewsletterCategoryEnum>();

  const { data: newsletterList, isLoading } = useNewsLettersFindAll(
    { ...pageIngo, userKlass: [1, 2, 3, 4, 5, 6, 7, 8, 9] },
    {
      query: {
        queryKey: [...QueryKey.teacher.newsletterList],
      },
    },
  );

  const newsletters = category
    ? newsletterList?.items.filter((newsletter: Newsletter) => newsletter.category === category)
    : newsletterList?.items;

  const unReadnewslettersList = newsletterList?.unreadIdList;

  return { newsletters, category, isLoading, unReadnewslettersList, setCategory };
}
