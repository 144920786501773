import { debounce } from 'lodash';
import { ChangeEvent, useContext, useState } from 'react';
import { Select } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { Checkbox, useCheckbox } from 'src/components/common/Checkbox';
import { Pagination } from 'src/components/common/Pagination';
import { SearchInput } from 'src/components/common/SearchInput';
import {
  parentManagementGetParents,
  useAdminCommonFindAllKlassBySchool,
  useParentManagementGetParents,
} from 'src/generated/endpoint';
import { useLanguage } from 'src/hooks/useLanguage';
import { useSearch } from 'src/lib/router';
import { Routes } from 'src/routes';
import { exportCSVToExcel } from 'src/util/download-excel';
import { AdminContext } from '../AdminMainPage';

export function ParentPage() {
  const { year } = useContext(AdminContext);
  const { page, size } = useSearch({ page: 1, size: 25 });
  const [klassName, setKlassName] = useState('');
  const [firstVisit, setFirstVisit] = useState<boolean>();
  const [keyword, setKeyword] = useState('');
  const { t } = useLanguage();

  const { data: klasses } = useAdminCommonFindAllKlassBySchool({ year });
  const { data: parents } = useParentManagementGetParents(
    { page, size, year, klass: klassName, first_visit: firstVisit, keyword },
    { query: { keepPreviousData: true } },
  );

  const cb = useCheckbox(parents?.items);
  const ids = cb.items.map(({ id }) => id);

  // 버튼 추가할 필요가 생기면 활용
  // async function deleteParent() {
  //   if (!confirm(`항목 ${cb.items.length}개를 삭제할까요?`)) return;
  //   await Promise.all(ids.map((id) => parentManagementDeleteParent(id)));
  //   cb.clear();
  // }

  async function downloadAsExcel() {
    const { items } = await parentManagementGetParents({ size: 10000, year });
    const content =
      '학급,이름,이메일,전화번호,학생이름\n' +
      items
        .map((item) => [item.childrenKlassGroupName, item.name, item.email, item.phone, item.childrenName].join())
        .join('\n');
    exportCSVToExcel(content, '보호자');
  }

  function onFirstVisitChange(e: ChangeEvent<HTMLSelectElement>) {
    if (e.target.value === 'undefined') return setFirstVisit(undefined);
    if (e.target.value === 'false') return setFirstVisit(false);
    if (e.target.value === 'true') return setFirstVisit(true);
  }

  const onKeywordChange = debounce((e: ChangeEvent<HTMLInputElement>) => setKeyword(e.target.value), 300);

  return (
    <Admin.Section>
      <Admin.H2>{t('parent')}</Admin.H2>

      <div className="flex items-center justify-between">
        <div className="flex gap-2">
          <Select value={klassName} onChange={(e) => setKlassName(e.target.value)}>
            <option value="">{t('select_class')}</option>
            {klasses
              ?.reduce((acc: any[], current: any) => {
                if (!acc.find((item) => item.id === current.id)) {
                  acc.push(current);
                }
                return acc;
              }, [])
              .map((k) => (
                <option key={k.id} value={k.name ?? ''}>
                  {k.name}
                </option>
              ))}
          </Select>
          {/* <Select onChange={onFirstVisitChange}>
            <option value="undefined">가입여부</option>
            <option value="false">가입완료</option>
            <option value="true">가입대기</option>
          </Select> */}
        </div>
        <div className="flex gap-2">
          <SearchInput placeholder={`${t('search')}`} onChange={onKeywordChange} />
        </div>
      </div>

      <div className="flex gap-2">
        {/* <Button.sm
          children="삭제"
          title="가입여부 X 상태의 사용자만 삭제 가능합니다"
          disabled={cb.allUnchecked || cb.items.some((item) => !item.firstVisit)}
          onClick={deleteParent}
          className="outlined-gray"
        /> */}
        <Button.sm children={t('download_form')} onClick={downloadAsExcel} className="outlined-gray" />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell>
              <Checkbox checked={cb.allChecked} onChange={cb.clickAll} />
            </Admin.TableHCell>
            <Admin.TableHCell children={t('grade_class')} />
            <Admin.TableHCell children={t('name')} />
            <Admin.TableHCell children={t('email')} />
            <Admin.TableHCell children={t('phone_number')} />
            <Admin.TableHCell children={t('student_name')} />
            <Admin.TableHCell children={t('is_primary_guardian')} />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {parents?.items.map((parent, i) => (
            <Admin.TableRow key={`${parent.id}-${parent.childrenId}`} to={`${Routes.admin.parent.index}/${parent.id}`}>
              <Admin.TableCell onClick={(e) => (e.stopPropagation(), cb.click(i))}>
                <Checkbox checked={cb.checked(i)} onChange={() => cb.click(i)} />
              </Admin.TableCell>
              <Admin.TableCell children={parent.childrenKlassGroupName} />
              <Admin.TableCell children={parent.name} />
              <Admin.TableCell children={parent.email} />
              <Admin.TableCell children={parent.phone} />
              <Admin.TableCell children={parent.childrenName} />
              <Admin.TableCell children={parent.isPrimaryGuardian ? 'O' : 'X'} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>

      <Pagination data={parents} />
    </Admin.Section>
  );
}
