import clsx from 'clsx';
import { ButtonHTMLAttributes } from 'react';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

export function Button({ className, ...props }: ButtonProps) {
  return <button className={clsx('button', className)} {...props} />;
}

Button.xs = function ButtonXs({ className, ...props }: ButtonProps) {
  return <button className={clsx('button-xs', className)} {...props} />;
};

Button.sm = function ButtonSm({ className, ...props }: ButtonProps) {
  return <button className={clsx('button-sm', className)} {...props} />;
};

Button.lg = function ButtonLg({ className, ...props }: ButtonProps) {
  return <button className={clsx('button-lg', className)} {...props} />;
};

Button.xl = function ButtonXl({ className, ...props }: ButtonProps) {
  return <button className={clsx('button-xl', className)} {...props} />;
};
