import { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { studentManagementBulkCreateStudent } from 'src/generated/endpoint';
import { RequestCreateStudentDto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { AdminContext } from '../AdminMainPage';

export function StudentBatchPage() {
  const { goBack } = useHistory();
  const { year } = useContext(AdminContext);
  const [items, setItems] = useState<RequestCreateStudentDto[]>([]);
  const { t } = useLanguage();

  async function readFile(file: File) {
    const [, ...rows] = await readXlsxFile(file);
    const items = rows.map(([grade, klass, studentNumber, name, email, barcode, nokName, nokPhone]) => {
      return { grade, klass, studentNumber, name, email, barcode, nokName, nokPhone };
    });
    setItems(items as any);
  }

  async function create() {
    if (items.length === 0) return;
    if (!confirm(`항목 ${items.length}개를 추가하시겠습니까?`)) return;
    await studentManagementBulkCreateStudent(
      items.map((item) => ({
        ...item,
        grade: Number(item.grade),
        klass: Number(item.klass),
        studentNumber: Number(item.studentNumber),
        year,
      })),
    );
    goBack();
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2 className="mb-4">{t('bulk_add_students')}</Admin.H2>

      <div className="flex gap-2">
        <a
          children={t('download_form')}
          href="https://kr.object.gov-ncloudstorage.com/superschool/storage/%ED%95%99%EC%83%9D%EB%93%B1%EB%A1%9D%EC%A0%95%EB%B3%B4.xlsx"
          className="button-sm outlined-gray"
        />
        <label className="button-sm outlined-gray cursor-pointer">
          <p>{t('select_excel_file')}</p>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => e.target.files?.[0] && readFile(e.target.files[0])}
            className="sr-only"
          />
        </label>
        <Button.sm children={t('bulk_add')} disabled={items.length === 0} onClick={create} className="outlined-gray" />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell children={t('email')} />
            <Admin.TableHCell children={t('name')} />
            <Admin.TableHCell children={t('grade')} />
            <Admin.TableHCell children={t('class_section')} />
            <Admin.TableHCell children={t('attendance_number')} />
            <Admin.TableHCell />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {items.map((item, i) => (
            <Admin.TableRow key={i}>
              <Admin.TableCell children={item.email} />
              <Admin.TableCell children={item.name} />
              <Admin.TableCell children={item.grade} />
              <Admin.TableCell children={item.klass} />
              <Admin.TableCell children={item.studentNumber} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>
    </Admin.Section>
  );
}
