import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Linkify from 'react-linkify';
import { useParams } from 'react-router';
import Viewer from 'react-viewer';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, Section, TopNavbar } from 'src/components/common';
import { FeedsDetail } from 'src/components/common/FeedsDetail';
import { PdfCard } from 'src/components/common/PdfCard';
import { PdfViewer } from 'src/components/common/PdfViewer';
import { Time } from 'src/components/common/Time';
import { Constants } from 'src/constants';
import { useStudentBoardDetail } from 'src/container/student-board-detail';
import { DateFormat, DateUtil } from 'src/util/date';
import { getFileNameFromUrl } from 'src/util/file';

export function BoardDetailPage() {
  const { id } = useParams<{ id: string }>();

  const { board, isBoardLoading, images, Pdfs, documents, viewerImages, errorMessage } = useStudentBoardDetail(+id);

  const [hasImagesModalOpen, setImagesModalOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [hasPdfModalOpen, setPdfModalOpen] = useState(false);
  const [focusPdfFile, setFocusPdfFile] = useState('');

  return (
    <div>
      {isBoardLoading && <Blank />}
      {errorMessage && <ErrorBlank />}
      <TopNavbar
        title="학급 게시판"
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />
      <FeedsDetail
        category1={board?.category}
        category1Color="mint_green"
        sendTo={
          (board?.toStudent ? '학생' : '') +
          (board?.toStudent && board?.toParent ? '/' : '') +
          (board?.toParent ? '보호자' : '')
        }
        sendToColor="gray-100"
        title={board?.title}
        contentText={board?.content}
        contentImages={board?.images}
        contentFiles={board?.files}
        writer={board?.writer.name}
        createAt={DateUtil.formatDate(board?.createdAt || '', DateFormat['YYYY.MM.DD HH:mm'])}
      />
    </div>
  );
}
