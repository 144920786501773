/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type SubjectType = (typeof SubjectType)[keyof typeof SubjectType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SubjectType = {
  LECTURE: 'LECTURE',
  ACTIVITY: 'ACTIVITY',
  ETC: 'ETC',
} as const;
