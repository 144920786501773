import { useLocation } from 'react-router';
import { StudentGroup, StudentNewsletter } from 'src/generated/model';

interface NewsletterSubmitterItemProps {
  filter: number;
  studentGroup?: StudentGroup;
  studentNewsletter?: StudentNewsletter;
  onClick: () => void;
  id: string;
}

export function NewsletterSubmitterItem({
  filter,
  studentGroup,
  studentNewsletter,
  onClick,
  id,
}: NewsletterSubmitterItemProps) {
  const { pathname } = useLocation();

  if (filter === 1 && (!studentNewsletter || !studentNewsletter?.isSubmitted)) {
    return <></>;
  }
  if (filter === 2 && studentNewsletter && studentNewsletter.isSubmitted) {
    return <></>;
  }

  return (
    <div className="inline-block min-w-1/2-2 cursor-pointer p-1 text-center" onClick={onClick}>
      <div
        className={
          studentNewsletter?.id
            ? pathname.includes(`/teacher/activity/submit/${id}/${studentNewsletter?.id}`)
              ? 'flex items-center justify-between space-x-2 rounded-md border border-brand-1 bg-light_orange p-2'
              : 'flex items-center justify-between space-x-2 rounded-md border p-2'
            : 'flex items-center justify-between space-x-2 rounded-md border p-2'
        }
      >
        {studentNewsletter?.isSubmitted ? (
          <>
            <div className="flex items-center">
              <span className="rounded-md bg-brand-1 px-2 py-1 text-sm text-white">제출</span>
              <div className="ml-2 flex space-x-2">
                <span className="font-semibold">{studentNewsletter?.studentNumber}</span>
                <span>{studentNewsletter?.student?.name}</span>
              </div>
            </div>
          </>
        ) : (
          <div className="flex cursor-pointer items-center">
            <span
              className={
                pathname.startsWith(`/teacher/activity/submit/${id}/${studentNewsletter?.id}`)
                  ? 'rounded-md border border-gray-200 bg-gray-100 px-2 py-1 text-sm text-gray-500'
                  : 'rounded-md bg-gray-100 px-2 py-1 text-sm text-gray-500'
              }
            >
              미제출
            </span>
            <div className="ml-2 flex space-x-2">
              <span className="font-semibold">{studentGroup?.studentNumber}</span>
              <span>{studentGroup?.user.name}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
