import { useHistory } from 'react-router-dom';
import { Blank } from 'src/components/common';
import { Button } from './common/Button';

interface ErrorBlankProps {
  text?: string;
}

export function ErrorBlank({
  text = '삭제된 컨텐츠이거나 일시적 오류입니다. 잠시후 다시 시도해주세요.',
}: ErrorBlankProps) {
  const { push } = useHistory();

  return (
    <Blank>
      <div className="mb-8 bg-transblack text-white">{text}</div>
      <div className="flex w-full items-center justify-center space-x-4">
        <Button.lg children="새로고침하기" onClick={() => window.location.reload()} className="filled-gray-dark" />
        <Button.lg children="홈으로 돌아가기" onClick={() => push('/')} className="filled-gray-dark" />
      </div>
    </Blank>
  );
}
