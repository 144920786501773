import { useState } from 'react';

interface YoutubeLinkProps {
  ContentTitle: string;
  videoId: string;
}

export function YoutubeLink({ ContentTitle, videoId }: YoutubeLinkProps) {
  const apiKey = 'AIzaSyBAtyZhUd0e-HnxCa1VYILdGWf7HKiaNC4';

  const [videoTitel, setVideoTitel] = useState('');
  const [videoUrl, setVideoUrl] = useState('');

  fetch(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=${apiKey}`)
    .then((response) => response.json())
    .then((data) => {
      const videoInfo = data.items[0].snippet;
      const title = videoInfo.title;
      const thumbnailUrl = videoInfo.thumbnails.medium.url;

      setVideoTitel(title);
      setVideoUrl(thumbnailUrl);
    })
    .catch((error) => {
      console.error('에러 발생:', error);
    });

  return (
    <a href={`https://youtu.be/${videoId}`} target="blank">
      <div className="mx-4 flex">
        <div className="w-[40%] ">
          <img src={videoUrl} className="w-full rounded-lg" />
        </div>
        <div className="ml-3 w-[60%]">
          <div className="font-bold">{ContentTitle}</div>
          <div className="mt-1 text-sm">{videoTitel}</div>
          <div className="mt-1 flex items-center">
            <img
              className="w-7"
              src="https://yt3.googleusercontent.com/41Ce-ERYVAi_yJlr89eLu8niIVfe0sXbffen5ayRGgYE6t_2cMRIfS3_nR-gdk3qKIZvqISrP3M=s176-c-k-c0x00ffffff-no-rj"
            />
            <div className="text-sm text-gray-600">슈퍼스쿨 슈퍼스터디</div>
          </div>
        </div>
      </div>
    </a>
  );
}
