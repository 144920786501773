import { useEffect, useState } from 'react';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { useRecoilValue } from 'recoil';
import { Constants } from 'src/constants';
import { QueryKey } from 'src/constants/query-key';
import { useNoticesFindOne } from 'src/generated/endpoint';
import { childState } from 'src/store';
import { isPdfFile } from 'src/util/file';

export function useStudentNoticeDetail(noticeId?: number) {
  const [errorMessage, setErrorMessage] = useState('');
  const child = useRecoilValue(childState);
  const {
    data: notice,
    isLoading: isNoticeLoading,
    refetch: refetchNotice,
  } = useNoticesFindOne(noticeId as number, {
    query: {
      enabled: !!noticeId,
      queryKey: [...QueryKey.teacher.notice, noticeId],
      onError: () => {
        setErrorMessage('공지사항을 불러오는데 실패했습니다.');
      },
    },
    request: {
      headers: { 'child-user-id': child?.id },
    },
  });

  useEffect(() => {
    if (!!child) {
      refetchNotice();
    }
  }, [child]);

  const images = notice?.images.filter((image) => !isPdfFile(image)) || [];
  const Pdfs = notice?.images.filter((image) => isPdfFile(image)) || [];
  const files = notice?.files || [];

  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    if (isPdfFile(image) == false) {
      viewerImages.push({
        src: `${Constants.imageUrl}${image}`,
      });
    }
  }

  return {
    notice,
    isNoticeLoading,
    images,
    Pdfs,
    files,
    viewerImages,
    errorMessage,
  };
}
