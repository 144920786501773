import clsx from 'clsx';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BackButton, Blank, TopNavbar } from 'src/components/common';
import { ResponseTimetableV3Dto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { getThisSemester, getThisYear } from 'src/util/time';
import { TimetableAttendancePage } from './TimetableAttendancePage';
import { TimetableDetailPage } from './TimetableDetailPage';

export function TimetablePage() {
  const { goBack } = useHistory();
  const [blankOpen, setBlankOpen] = useState(false);
  const [selectedLectureInfo, setSelectedLectureInfo] = useState<ResponseTimetableV3Dto | undefined>();
  const [isTeacherView, setIsTeacherView] = useState<boolean>(true);
  const { t } = useLanguage();

  const year = +getThisYear();
  const semester = +getThisSemester();

  return (
    <>
      {/* Mobile V */}
      <div className="block md:hidden">
        {blankOpen && <Blank />}
        <TopNavbar
          title="시간표/출석체크"
          left={<BackButton onClick={() => (selectedLectureInfo ? setSelectedLectureInfo(undefined) : goBack())} />}
          right={
            <div
              onClick={() => {
                setBlankOpen(true);
                window?.location?.reload();
              }}
              className="text-sm text-brand-1"
            >
              새로고침
            </div>
          }
        />

        <div className={clsx(selectedLectureInfo && 'hidden')}>
          <TimetableDetailPage
            year={year}
            semester={semester}
            selectedLectureInfo={(info: ResponseTimetableV3Dto | undefined) => setSelectedLectureInfo(info)}
            isTeacherView={(value: boolean) => setIsTeacherView(value)}
          />
        </div>
      </div>

      {/* Desktop V */}
      <div className="col-span-2 hidden h-screen md:block">
        <div className="px-6 py-6">
          <h1 className="text-2xl font-semibold">
            {t('timetable', '시간표')}/{t('attendance_check', '출석체크')}
          </h1>
        </div>
        <div className="h-0.5 bg-gray-100"></div>
        <div className="scroll-box hidden h-screen-6 w-full overflow-y-auto md:block ">
          <TimetableDetailPage
            year={year}
            semester={semester}
            selectedLectureInfo={(info: ResponseTimetableV3Dto | undefined) => setSelectedLectureInfo(info)}
            isTeacherView={(value: boolean) => setIsTeacherView(value)}
          />
        </div>
      </div>

      {/* Mobile / Desktop V */}
      <div className="p-6 md:col-span-4  md:block">
        {selectedLectureInfo && (
          <TimetableAttendancePage selectedLectureInfo={selectedLectureInfo} isTeacherView={isTeacherView} />
        )}
      </div>
    </>
  );
}
