import { BackButton, TopNavbar } from 'src/components/common';
import { NotificationPage } from 'src/components/notificationSettings/NotificationPage';

export function NotificationSettingsPage() {
  return (
    <>
      <TopNavbar title="알림설정" left={<BackButton />} />

      <NotificationPage />
    </>
  );
}
