import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { toastState } from 'src/store';

// 사용법 :
// const setToastMsg = useSetRecoilState(toastState);
// setToastMsg("토스트 표시할 메시지");

interface ToastProps {
  setToast: (toast: boolean) => void;
  text: string;
}

export function Toast() {
  const [toastMsg, setToastMsg] = useRecoilState(toastState);

  useEffect(() => {
    const timer = setTimeout(() => {
      setToastMsg(undefined);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [toastMsg]);

  return (
    <>
      {toastMsg && (
        <div className="fixed bottom-2 left-1/2 z-[5000] w-3/4 -translate-x-1/2 transform p-4  text-white   md:bottom-5 md:w-[600px]">
          <div className="rounded-lg bg-black bg-opacity-50 p-4 text-center backdrop-blur-sm">{toastMsg}</div>
        </div>
      )}
    </>
  );
}
