import { useEffect, useState } from 'react';
import { useGroupsFindSubjectByUser } from 'src/generated/endpoint';
import { ResponseSubjectGroupDto } from 'src/generated/model/responseSubjectGroupDto';
import { nameWithId } from 'src/types';
import { getThisYear } from 'src/util/time';

export function useTeacherActivity() {
  const [errorMessage, setErrorMessage] = useState('');
  const [subjectData, setSubjectData] = useState<ResponseSubjectGroupDto[]>([]);
  const [year, setYear] = useState(getThisYear());

  const [subject, setSubject] = useState<string>();
  const [subjectArray, setSubjectArray] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<nameWithId | null>(null);
  const [groupArray, setGroupArray] = useState<nameWithId[]>([]);

  const { isLoading: isActivityLoading } = useGroupsFindSubjectByUser(
    { year },
    {
      query: {
        onSuccess: (res) => {
          if (!res?.length) {
            return;
          }

          setSubjectData(res);

          const uniqueSubjects: string[] = [...new Set(res.map((item) => item.subject))];
          setSubjectArray(uniqueSubjects);
          setSubject('');

          setGroupArray([{ id: 0, name: '전체' }]);
          setSelectedGroup(groupArray[0]);
        },
        onError: () => {
          setErrorMessage('활동을 불러오는데 실패했습니다.');
        },
      },
    },
  );

  useEffect(() => {
    if (subject) {
      const filteredData: nameWithId[] = subjectData
        .filter((item) => item.subject === subject)
        .sort((a: ResponseSubjectGroupDto, b: ResponseSubjectGroupDto) => {
          const aData = a?.group.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);
          const bData = b?.group.name?.match(`([0-9]|[0-9][0-9])학년 ([0-9]|[0-9][0-9])반`);

          if (!aData || !bData) {
            return 0;
          }

          if (aData[1] && bData[1]) {
            if (aData[1] === bData[1]) {
              return Number(aData[2]) - Number(bData[2]);
            } else {
              return Number(aData[1]) - Number(bData[1]);
            }
          } else {
            return !a.group.name || !b.group.name ? -1 : a?.group?.name < b.group.name ? -1 : 1;
          }

          return 0;
        })
        .map((item) => ({ id: item.group.id, name: item.group.name }));

      setGroupArray([{ id: 0, name: '전체' }, ...filteredData]);
    }
  }, [subject]);

  return {
    subject,
    setSubject,
    selectedGroup,
    setSelectedGroup,
    subjectArray,
    groupArray,
    errorMessage,
    setYear,
    year,
  };
}
