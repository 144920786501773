import { useImagesUploadImage } from 'src/generated/endpoint';
import { UploadFileTypeEnum } from 'src/generated/model';

export function useFileUpload() {
  const { mutateAsync: uploadImageMutate, isLoading: isUploadLoading } = useImagesUploadImage({});

  async function handleUploadFile(uploadFileType: UploadFileTypeEnum, files: File[]) {
    try {
      const promises = files.map((file) => uploadImageMutate({ uploadFileType, data: { file } }));
      return Promise.all(promises);
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  return { handleUploadFile, isUploadLoading };
}
