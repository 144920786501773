import { useState } from 'react';
import { useCoulselingFindCoulselingDetialStudentByStudentId } from 'src/generated/endpoint';
import { ResponseCoulselingDetailStudentDto } from 'src/generated/model';

export function useTeacherStudentCard(studentId?: number) {
  const [isForbidden, setIsForbidden] = useState(false);

  const { data: studentInfo, isLoading } =
    useCoulselingFindCoulselingDetialStudentByStudentId<ResponseCoulselingDetailStudentDto>(studentId || 0, {
      query: {
        enabled: !!studentId && !isForbidden,
        onSuccess: () => {
          setIsForbidden(false);
        },
        onError: () => {
          setIsForbidden(true);
        },
      },
    });

  return { studentInfo, isForbidden, isLoading };
}
