import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';
import { App } from './App';
import './calendar.css';
import { GroupContainer } from './container/group';
import { UserContainer } from './container/user';
import './index.css';
import { queryClient } from './lib/query';
import './util/i18n';

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <UserContainer.ContextProvider>
          <GroupContainer.ContextProvider>
            <App />
          </GroupContainer.ContextProvider>
        </UserContainer.ContextProvider>
      </QueryClientProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);
