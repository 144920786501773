import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, TopNavbar } from 'src/components/common';
import { Button } from 'src/components/common/Button';
import { OutingDetail } from 'src/components/outing/OutingDetail';
import { useStudentOutingDetail } from 'src/container/student-outing-detail';
import { UserContainer } from 'src/container/user';
import { useSignedUrl } from 'src/lib/query';
import { OutingAddPage } from './OutingAddPage';

export function OutingDetailPage() {
  const { id } = useParams<{ id: string }>();
  const { me } = UserContainer.useContext();
  const { outing, isLoading, error, deleteOuting, errorMessage } = useStudentOutingDetail(Number(id));

  const school = me?.school;
  const schoolName = me?.school?.name;

  const [mode, setMode] = useState(false);

  const isNotParent = me?.role !== 'PARENT';

  const { data: approver1Signature } = useSignedUrl(outing?.approver1Signature);
  const { data: approver2Signature } = useSignedUrl(outing?.approver2Signature);
  const { data: approver3Signature } = useSignedUrl(outing?.approver3Signature);
  const { data: approver4Signature } = useSignedUrl(outing?.approver4Signature);
  const { data: approver5Signature } = useSignedUrl(outing?.approver5Signature);

  const isProccesing =
    !!approver1Signature ||
    !!approver2Signature ||
    !!approver3Signature ||
    !!approver4Signature ||
    !!approver5Signature;

  if (mode && outing) {
    return (
      <OutingAddPage
        outingData={outing}
        goDetail={() => {
          setMode(false);
        }}
      />
    );
  }

  return (
    <>
      {isLoading && <Blank />}
      {error && <ErrorBlank />}
      {isLoading && <Blank />}
      <TopNavbar
        title={'확인증'}
        left={
          <div className="h-15">
            <BackButton className="h-15" />
          </div>
        }
      />

      <div className=" px-3 pt-5">
        {outing?.outingStatus === 'RETURNED' && (
          <div className="flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
            <div className="text-sm text-brand-1">{outing?.notApprovedReason}</div>
            <div className="text-red-500">반려 이유</div>
          </div>
        )}
        {outing?.outingStatus === 'DELETE_APPEAL' && (
          <div className="flex items-center justify-between rounded-lg bg-brand-5 px-5 py-2">
            <div className="text-sm text-brand-1">{outing?.deleteReason}</div>
            <div className="text-red-500">삭제 이유</div>
          </div>
        )}
        {outing?.outingStatus === 'PROCESSED' && (
          <div className="rounded-lg bg-brand-5 px-5 py-2">
            <div className="text-red-500">승인완료</div>
          </div>
        )}
        {outing?.outingStatus === 'BEFORE_TEACHER_APPROVAL' && (
          <div className="rounded-lg bg-brand-5 px-5 py-2">
            <div className="text-gray-900">승인 전</div>
          </div>
        )}
        <br />
        <OutingDetail outing={outing} />
      </div>

      {isNotParent && outing?.outingStatus !== 'PROCESSED' && (
        <div className="px-2">
          <div className="text-red-500">{errorMessage}</div>
          <Button.lg
            children="수정하기"
            disabled={outing?.outingStatus === 'DELETE_APPEAL' || (outing?.outingStatus !== 'RETURNED' && isProccesing)}
            onClick={() => setMode(true)}
            className="filled-primary w-full"
          />

          {/* {(outing?.outingStatus === 'DELETE_APPEAL' || outing?.outingStatus === 'RETURNED') && ( */}
          <Button.lg
            children="삭제하기"
            disabled={outing?.outingStatus !== 'DELETE_APPEAL' && outing?.outingStatus !== 'RETURNED' && isProccesing}
            onClick={() => deleteOuting()}
            className="filled-red mt-2 w-full"
          />
          {/* )} */}
        </div>
      )}
    </>
  );
}
