import { useRecoilValue } from 'recoil';
import { useStudentGroupsFindKlassHistoryByStudent } from 'src/generated/endpoint';
import { meState } from 'src/store';

export function useStudentKlassHistory() {
  const meRecoil = useRecoilValue(meState);

  const { data: klassHistoryList } = useStudentGroupsFindKlassHistoryByStudent({
    query: {
      enabled: !!meRecoil,
    },
  });

  return { klassHistoryList };
}
