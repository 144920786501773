import { PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from 'src/hooks/useLanguage';
import { Label } from './common';
import { Button } from './common/Button';
import { Checkbox } from './common/Checkbox';

interface NoticePopupProps {
  noticeOpen: boolean;
  setNoticeClose: () => void;
  width?: string;
  ablePropragation?: boolean;
}

export function NoticePopup2({
  noticeOpen,
  setNoticeClose,
  width = 'w-80',
  ablePropragation = false,
}: PropsWithChildren<NoticePopupProps>) {
  const [showNever, setShowNever] = useState(false);
  const title = '보안 정책 변경에 따른 로그아웃';
  const { t, currentLang } = useLanguage();

  const noticeShow = localStorage.getItem('noticeShow2');

  if (noticeShow) {
    setNoticeClose();
  }

  const handleClose = () => {
    if (showNever) {
      localStorage.setItem('noticeShow2', 'not');
    }
    setNoticeClose();
  };

  useEffect(() => {
    const noticeShow = localStorage.getItem('noticeShow2');
    if (noticeShow) {
      setNoticeClose();
    }
  }, [setNoticeClose]);

  return (
    <div
      className={`fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-littleblack ${
        !noticeOpen && 'hidden'
      }`}
      onClick={(e) => {
        if (!ablePropragation) {
          e.preventDefault();
          e.stopPropagation();
        }
      }}
    >
      <div className="relative w-4/5 rounded-lg bg-white p-4 opacity-100 md:w-[440px]">
        {/* <div className="absolute right-3 top-3">
          <CloseButton onClick={() => setNoticeClose()} />
        </div> */}
        <div className="text-center text-2xl font-bold">{title}</div>
        <div className="h-4"></div>
        {/* <div className="whitespace-pre-line text-lg">{contents}</div> */}
        <div className="flex flex-col gap-2">
          <p>
            <b className="font-bold">1.</b> <b className="text-lg text-brand-1">2학기 정부정책에</b> 따른 보안강화가
            적용되었습니다.
          </p>
          <p className="text-gray-600">
            정책시행에 의해 모든 사용자의 <b>재 로그인</b>이 필요합니다.
          </p>
          <p>
            <b className="font-bold">2.</b> 비밀번호가 기억나지 않으실경우 아래의 비밀번호 찾기를 눌러주세요.
          </p>
          <p>
            <b>3.</b> 5회이상 로그인 실패시 계정이 잠길 수 있습니다.
          </p>
        </div>
        <div className="flex items-center justify-center gap-2 pt-6">
          <Link to="/find-password">
            <button className="rounded-md border border-gray-600 bg-white p-4 text-lg font-bold text-brand-1 hover:bg-gray-500 hover:text-white">
              비밀번호 찾기 및 초기화
            </button>
          </Link>
        </div>
        <div className="my-3 h-0.5 bg-brand-1"></div>
        <div className="flex items-center justify-between">
          <div className="flex space-x-3">
            <Checkbox checked={showNever} onChange={() => setShowNever(!showNever)} />
            <Label.Text children={t('do_not_show_again')} />
          </div>
          <Button.lg children={t('close')} onClick={() => handleClose()} className="filled-gray-300" />
        </div>
      </div>
    </div>
  );
}
