/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type Category = (typeof Category)[keyof typeof Category];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Category = {
  studentstatus: 'studentstatus',
  teacherstatus: 'teacherstatus',
  coulselingtype: 'coulselingtype',
  noticetype: 'noticetype',
  outingtype: 'outingtype',
  absenttype: 'absenttype',
  creativeActivity: 'creativeActivity',
  subjectType: 'subjectType',
} as const;
