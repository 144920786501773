import { ComponentType } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuth } from 'src/util/hooks';

interface AuthRouteProps {
  path: string;
  component: ComponentType;
  guestOnly?: boolean;
}

export function AuthRoute({ path, component: Component, guestOnly }: AuthRouteProps) {
  const { authenticated, twoFactorAuthenticated } = useAuth();

  return (
    <Route
      path={path}
      render={({ location }) => {
        if (guestOnly && authenticated) {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }
        if (!guestOnly && !authenticated) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
        }

        if (!guestOnly && authenticated && !twoFactorAuthenticated && location.pathname !== '/two-factor') {
          return <Redirect to={{ pathname: '/two-factor', state: { from: location } }} />;
        }

        if (authenticated && twoFactorAuthenticated && location.pathname == '/two-factor') {
          return <Redirect to={{ pathname: '/', state: { from: location } }} />;
        }
        // @ts-ignore
        return <Component />;
      }}
    />
  );
}
