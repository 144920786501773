import { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Label } from 'src/components/common';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { TextInput } from 'src/components/common/TextInput';
import { klassManagementCreateKlass } from 'src/generated/endpoint';
import { RequestCreateKlassDto } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';
import { form } from 'src/lib/form';
import { Routes } from 'src/routes';
import { toastState } from 'src/store';
import { AdminContext } from '../AdminMainPage';

export function KlassEditPage() {
  const { push } = useHistory();
  const { id: idString } = useParams<{ id: string }>();
  const id = Number(idString);
  const setToastMsg = useSetRecoilState(toastState);
  const { year } = useContext(AdminContext);
  const { t } = useLanguage();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<RequestCreateKlassDto>();

  async function save(params: any) {
    if (id) return;
    const klass = await klassManagementCreateKlass({ ...params, year }).catch((error) =>
      alert(error.response.data.message),
    );
    if (!klass) return;
    setToastMsg(`${params.grade}학년 ${params.klass}반 학급이 생성되었습니다`);
    push(`${Routes.admin.klass.index}/${klass.id}`);
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2 className="mb-4">{t('add_class')}</Admin.H2>

      <Label.col>
        <Label.Text children={t('grade')} />
        <TextInput placeholder={`${t('grade')}`} type="number" {...register('grade', form.minmax(1, 20))} />
        <Label.Error children={errors.grade?.message} />
      </Label.col>
      <Label.col>
        <Label.Text children={t('class_section')} />
        <TextInput placeholder={`${t('class_section')}`} type="number" {...register('klass', form.minmax(1, 100))} />
        <Label.Error children={errors.klass?.message} />
      </Label.col>

      <div className="mt-4 grid grid-cols-2 gap-4">
        <Link
          children={t('cancel')}
          to={id ? `../${id}` : Routes.admin.klass.index}
          className="button-lg outlined-gray"
        />
        <Button.lg children={t('save')} disabled={!isValid} onClick={handleSubmit(save)} className="filled-gray" />
      </div>
    </Admin.Section>
  );
}
