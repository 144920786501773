import clsx from 'clsx';
import { PropsWithChildren } from 'react';
import { use100vh } from 'react-div-100vh';

interface BlankProps {
  text?: string;
  reversed?: boolean;
}

export function Blank({ text, reversed, children }: PropsWithChildren<BlankProps>) {
  const vh = use100vh();
  const height = vh ? `${vh}px` : '100vh';

  const backgroundColor = reversed ? 'bg-white' : 'bg-black';
  const textColor = reversed ? 'text-black' : 'text-white';

  if (!text && !children) {
    text = 'LOADING...';
  }

  return (
    <div
      style={{ height }}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={clsx(
        'fixed inset-0 z-100 m-0 flex h-screen w-full items-center justify-center opacity-50',
        backgroundColor,
      )}
    >
      <div className={`${textColor} text-2xl`}>
        {text} {children}
      </div>
    </div>
  );
}
