import { useRef, useState } from 'react';
import { ReactComponent as DownArrow } from 'src/assets/icons/chevron-down.svg';
import { ReactComponent as Plus } from 'src/assets/svg/plus.svg';
import { useCodeByCategoryName } from 'src/container/category';
import { useTeacherCoulseling } from 'src/container/teacher-coulseling';
import { UserContainer } from 'src/container/user';
import { Category, Code, Coulseling, ResponseCoulselingDetailDto } from 'src/generated/model';
import { isValidDate } from 'src/util/time';
import { Blank, Select } from '../common';
import { TextInput } from '../common/TextInput';
import Recorder from '../rec/Recorder';
import CoulselingDetailCard from './CoulselingDetailCard';

interface CoulselingCardProps {
  studentId: number;
}

export default function Coulselingv3Card({ studentId }: CoulselingCardProps) {
  const { me } = UserContainer.useContext();
  const [page, setPage] = useState(1);
  const [uploading, setUploading] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const {
    isAddMode,
    setIsAddMode,
    content,
    setContent,
    coulselingAt,
    setCoulselingAt,
    category,
    setCategory,
    coulselorName,
    setCoulselorName,
    userId,
    setUserId,
    coulseingData,
    createCoulseling,
    deleteCoulseling,
    isLoading,
  } = useTeacherCoulseling(studentId);

  const recorderRef = useRef<{ uploadAndDestroy: (save: boolean) => void }>(null);

  const handleUpload = (fileNames: string[]) => {
    setUploading(false);
    if (fileNames.length === 0 || (fileNames[0] && fileNames[0] !== 'hold')) {
      createCoulseling(fileNames);
    }
  };

  const { categoryData: coulselingType } = useCodeByCategoryName(Category.coulselingtype);

  const [categoryObj, setCategoryObj] = useState<Code | undefined>();

  const categoryChanged = (item: Code) => {
    setCategoryObj(item);
    setCategory(item.name);
  };

  const filteredData = coulseingData?.filter((item: ResponseCoulselingDetailDto) => item.writerId === me?.id) || [];

  let MAXPAGE = 0;
  if (filteredData.length > 0) {
    MAXPAGE = Math.ceil(filteredData.length / 6);
  }

  const handleSave = () => {
    if (!categoryObj || categoryObj?.key < 0) {
      alert('카테고리를 선택해주세요.');
      return;
    }

    if (!content.trim()) {
      alert('내용을 입력해주세요.');
      return;
    }

    if (recorderRef.current) {
      setUploading(true);
      recorderRef.current.uploadAndDestroy(true);

      // 녹음파일 업로드가 완료된 이후 처리되도록 handleUpload() 로 이전
      //createCoulseling();
    } else {
      createCoulseling();
    }
  };

  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  if (uploading) return <Blank text="업로드 중입니다. 잠시만 기다려 주세요." />;
  if (isLoading) return <Blank />;

  return (
    <div ref={contentRef} className="scroll-box h-screen-12 overflow-y-auto pb-4 md:h-screen-4">
      <div className="relative mt-4 flex flex-col rounded-md border-2 bg-white p-4">
        <div className="flex items-center justify-between pt-3 md:pt-0">
          <h6 className="text-lg font-semibold">상담내역</h6>
          {filteredData.length !== 0 && (
            <button
              onClick={() => {
                setIsAddMode(true);
                setContent('');
                setCoulselorName(me?.name || '');
              }}
              className="flex h-8 w-24 items-center justify-center gap-1 rounded-lg border border-darkgray bg-white font-semibold transition-all hover:bg-darkgray hover:text-white"
            >
              <Plus />
              <p>상담 추가</p>
            </button>
          )}
        </div>
        {filteredData.length === 0 && (
          <div className="mt-4 flex h-[228px] flex-col items-center justify-center gap-4 rounded-[4px] bg-[#F3F3F3] text-sm">
            <button
              onClick={() => {
                setIsAddMode(true);
                setContent('');
                setCoulselorName(me?.name || '');
              }}
              className="flex h-8 w-24 items-center justify-center gap-1 rounded-lg border border-darkgray bg-white font-semibold transition-all hover:bg-darkgray hover:text-white"
            >
              <Plus />
              <p>상담 추가</p>
            </button>
            <p className="text-center text-[#999999]">
              상담 내역이 없습니다.
              <br />
              상담 추가 버튼을 눌러 내용을 추가해 보세요.
            </p>
          </div>
        )}
        {filteredData.length > 0 && (
          <div className="mt-4 flex grid-cols-2 flex-col gap-4 2xl:grid">
            {filteredData
              .slice()
              .reverse()
              .slice(0, 6 * page)
              .map((item: ResponseCoulselingDetailDto) => (
                <div key={item.id}>
                  <CoulselingDetailCard data={item} deleteCoulseling={deleteCoulseling} />
                </div>
              ))}
          </div>
        )}

        {filteredData.length > 6 && (
          <>
            {page < MAXPAGE ? (
              <button
                onClick={() => setPage((page) => page + 1)}
                className="mt-4 flex w-full items-center justify-center gap-2 rounded-md border-darkgray py-2 text-[#666666] transition-all hover:bg-darkgray hover:text-white md:border"
              >
                <DownArrow />
                <p>더 보기 ({`${page}/${MAXPAGE}`})</p>
              </button>
            ) : (
              <button
                onClick={() => {
                  setPage(1);
                  scrollToTop();
                }}
                className="mt-4 flex w-full items-center justify-center gap-2 rounded-md border border-darkgray py-2 text-[#666666] transition-all hover:bg-darkgray hover:text-white"
              >
                <p>접기</p>
              </button>
            )}
          </>
        )}
        {isAddMode && (
          <div className="fixed inset-0 z-60 flex h-screen w-full items-center justify-center bg-littleblack">
            <section className="relative mx-4 flex h-1/2 w-full flex-col rounded-lg bg-white p-6 pb-20 md:mx-0 md:h-1/2 md:w-1/2 md:pb-0">
              <div className="flex items-center justify-between md:pb-4">
                <h1 className="text-xl font-bold">상담카드 작성</h1>
                <button
                  className="hidden h-8 w-12 rounded-md border border-darkgray font-semibold transition-all hover:bg-darkgray hover:text-white md:block"
                  onClick={() => {
                    if (recorderRef.current) {
                      recorderRef.current.uploadAndDestroy(false);
                    }
                    setIsAddMode(false);
                  }}
                >
                  취소
                </button>
                {/* <p className="block md:hidden text-darkgray text-sm">상담 학생 : </p> */}
              </div>
              <nav className="mt-2 flex w-full flex-col justify-between md:mt-0 md:flex-row">
                <span className="flex gap-2 md:flex-row md:items-center md:gap-4">
                  <Select.lg
                    value={categoryObj?.key}
                    onChange={(e) => {
                      const selItem = coulselingType?.filter((item: Code) => item.key === Number(e.target.value));
                      selItem && categoryChanged(selItem[0]);
                    }}
                    className="h-10 w-1/2 rounded-md border border-[#DDDDDD] py-0 text-sm focus:border-black md:w-48"
                  >
                    {coulselingType?.map((item: Code) => (
                      <option key={item.key} value={item.key}>
                        {item.name}
                      </option>
                    ))}
                  </Select.lg>
                  <TextInput
                    type="date"
                    value={coulselingAt}
                    onChange={(e) => {
                      const selectedDate = new Date(e.target.value);
                      if (!isValidDate(selectedDate)) {
                        return;
                      }
                      setCoulselingAt(e.target.value);
                    }}
                    className="h-10 w-1/2 rounded-md border border-[#DDDDDD] text-sm focus:border-black md:w-48"
                  />
                </span>
                {/* <p className="hidden md:block">상담 학생 : </p> */}
                {me?.schoolId === 2 && <Recorder ref={recorderRef} onUpload={handleUpload} />}
              </nav>
              <textarea
                placeholder="상담 내용을 입력해 주세요."
                value={content}
                onChange={(e) => setContent(e.target.value)}
                className="mt-2 w-full flex-1 resize-none rounded-lg border border-[#DDDDDD] focus:border-black focus:ring-0"
              />
              <nav className="absolute inset-x-0 bottom-0 flex h-16 w-full md:hidden">
                <button className="w-1/2 border-t font-semibold" onClick={() => setIsAddMode(false)}>
                  취소
                </button>
                <button onClick={handleSave} className="w-1/2 rounded-br-lg bg-brand-1 text-white">
                  저장하기
                </button>
              </nav>
              <button
                onClick={handleSave}
                className="my-4 hidden h-10 w-full rounded-lg bg-brand-1 text-xl font-bold text-white md:block"
              >
                저장하기
              </button>
            </section>
          </div>
        )}
      </div>
    </div>
  );
}
