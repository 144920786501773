import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import readXlsxFile from 'read-excel-file';
import { Admin } from 'src/components/common/Admin';
import { Button } from 'src/components/common/Button';
import { teacherManagementBulkCreateTeacher } from 'src/generated/endpoint';
import { RequestCreateTeacherDto, Role } from 'src/generated/model';
import { useLanguage } from 'src/hooks/useLanguage';

export function TeacherBatchPage() {
  const { goBack } = useHistory();
  const [items, setItems] = useState<RequestCreateTeacherDto[]>([]);
  const { t } = useLanguage();

  async function readFile(file: File) {
    const [a, ...rows] = await readXlsxFile(file);
    const items = rows.map(([email, role, name, headNumber]) => ({ email, role, name, headNumber }));
    setItems(items as any);
  }

  async function create() {
    if (items.length === 0) return;
    if (!confirm(`항목 ${items.length}개를 추가하시겠습니까?`)) return;
    await teacherManagementBulkCreateTeacher(
      items.map((item) => ({ ...item, role: roleFromText(item.role), headNumber: Number(item.headNumber) })),
    );
    goBack();
  }

  return (
    <Admin.Section className="max-w-xl">
      <Admin.H2 className="mb-4">{t('bulk_add_teachers')}</Admin.H2>
      <div className="flex gap-2">
        <a
          children={t('download_form')}
          href="https://kr.object.gov-ncloudstorage.com/superschool/storage/%EA%B5%90%EC%82%AC%EB%93%B1%EB%A1%9D%EC%A0%95%EB%B3%B4.xlsx"
          className="button-sm outlined-gray"
        />
        <label className="button-sm outlined-gray cursor-pointer">
          <p>{t('select_excel_file')}</p>
          <input
            type="file"
            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            onChange={(e) => e.target.files?.[0] && readFile(e.target.files[0])}
            className="sr-only"
          />
        </label>
        <Button.sm children={t('bulk_add')} disabled={items.length === 0} onClick={create} className="outlined-gray" />
      </div>

      <Admin.Table>
        <Admin.TableHead>
          <Admin.TableRow>
            <Admin.TableHCell children={t('email')} />
            <Admin.TableHCell children={t('name')} />
            <Admin.TableHCell children={t('role')} />
            <Admin.TableHCell children={t('grade_head_teacher')} />
            <Admin.TableHCell />
          </Admin.TableRow>
        </Admin.TableHead>
        <Admin.TableBody>
          {items.map((item, i) => (
            <Admin.TableRow key={i}>
              <Admin.TableCell children={item.email} />
              <Admin.TableCell children={item.name} />
              <Admin.TableCell children={item.role} />
              <Admin.TableCell children={item.headNumber} />
            </Admin.TableRow>
          ))}
        </Admin.TableBody>
      </Admin.Table>
    </Admin.Section>
  );
}

function roleFromText(text: string) {
  switch (text) {
    case '교장선생님':
      return Role.HEAD_PRINCIPAL;
    case '교감선생님':
      return Role.VICE_PRINCIPAL;
    case '학년부장':
      return Role.HEAD;
    case '교무부장':
      return Role.PRINCIPAL;
    case '관리자':
      return Role.ADMIN;
    case '학년계':
      return Role.PRE_HEAD;
    case '교무계':
      return Role.PRE_PRINCIPAL;
    case '교감':
      return Role.VICE_PRINCIPAL;
    case '교장':
      return Role.HEAD_PRINCIPAL;
    case '보안관':
      return Role.SECURITY;
    case '교직원':
      return Role.STAFF;
    case '선생님':
    default:
      return Role.TEACHER;
  }
}
