import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { ImageDecorator } from 'react-viewer/lib/ViewerProps';
import { Constants } from 'src/constants';
import { QueryKey } from 'src/constants/query-key';
import { useBoardDelete, useBoardFindOne } from 'src/generated/endpoint';
import { Routes } from 'src/routes';
import { isPdfFile } from 'src/util/file';

export function useTeacherBoardDetail(boardId?: number) {
  const { push } = useHistory();
  const queryClient = useQueryClient();

  const [errorMessage, setErrorMessage] = useState('');

  const { data: board, isLoading: isBoardLoading } = useBoardFindOne(boardId as number, {
    query: {
      enabled: !!boardId,
      queryKey: [...QueryKey.teacher.board, boardId],
      onError: () => {
        setErrorMessage('공지사항을 불러오는데 실패했습니다.');
      },
    },
  });

  const { mutate: deleteBoard } = useBoardDelete({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.teacher.boardList);
        push(Routes.teacher.board);
      },
      onError: () => {
        setErrorMessage('공지사항 삭제에 실패했습니다.');
      },
    },
  });

  const images = board?.images.filter((image) => !isPdfFile(image)) || [];
  const Pdfs = board?.images.filter((image) => isPdfFile(image)) || [];
  const documents = board?.files || [];

  const viewerImages: ImageDecorator[] = [];
  for (const image of images) {
    if (isPdfFile(image) == false) {
      viewerImages.push({
        src: `${Constants.imageUrl}${image}`,
      });
    }
  }

  const handleBoardDelete = () => {
    deleteBoard({ id: boardId as number });
  };

  return {
    board,
    isBoardLoading,
    images,
    Pdfs,
    documents,
    viewerImages,
    errorMessage,
    handleBoardDelete,
  };
}
