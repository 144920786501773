import clsx from 'clsx';
import { HTMLAttributes, LabelHTMLAttributes } from 'react';

export interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {}

export function Label({ className, ...props }: LabelProps) {
  return <label className={clsx('label', className)} {...props} />;
}

Label.col = function LabelCol({ className, ...props }: LabelProps) {
  return <label className={clsx('label label-col', className)} {...props} />;
};

Label.row = function LabelRow({ className, ...props }: LabelProps) {
  return <label className={clsx('label label-row', className)} {...props} />;
};

export interface LabelTextProps extends HTMLAttributes<HTMLParagraphElement> {}

Label.Text = function LabelText({ className, ...props }: LabelTextProps) {
  return <p className={clsx('label-text', className)} {...props} />;
};

export interface LabelErrorProps extends HTMLAttributes<HTMLParagraphElement> {}

Label.Error = function LabelError({ className, ...props }: LabelErrorProps) {
  return <p className={clsx('label-error', className)} {...props} />;
};
