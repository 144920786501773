/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type FilterAbsentStatus = (typeof FilterAbsentStatus)[keyof typeof FilterAbsentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FilterAbsentStatus = {
  BEFORE_APPROVAL: 'BEFORE_APPROVAL',
  PROCESSED: 'PROCESSED',
  RETURNED: 'RETURNED',
  UNSUBMITTED: 'UNSUBMITTED',
  SUBMITTED: 'SUBMITTED',
} as const;
