import { useLocation } from 'react-router';

export function useSearch<Params extends Record<string, any>>(params = {} as Params) {
  const { search } = useLocation();
  const sp = new URLSearchParams(search);

  return Object.entries(params).reduce((acc, [key, defaultValue]) => {
    const value = sp.get(key) ?? defaultValue;
    acc[key as keyof Params] = typeof defaultValue === 'number' ? Number(value) : value;
    return acc;
  }, {} as Params);
}
