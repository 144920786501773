import { useState } from 'react';
import { ReactComponent as KlassIcon } from 'src/assets/svg/attendance-class.svg';
import { ReactComponent as UserIcon } from 'src/assets/svg/attendance-user.svg';
import { ErrorBlank } from 'src/components';
import { BackButton, Blank, Section, TopNavbar } from 'src/components/common';
import { useStudentTimetableDetail } from 'src/container/student-timetable-detail';
import { LectureType, ResponseTimetableV3Dto } from 'src/generated/model';
import { dayOfKorWeek } from 'src/util/date';

interface TimetableDetailPageProps {
  setShowTimetablePage: (b: boolean) => void;
}

interface TimetableContent {
  type: string;
  subject: string;
  teacher?: string;
  class?: string;
}

interface TimetableWeek {
  monday: TimetableContent;
  tuesday: TimetableContent;
  wednesday: TimetableContent;
  thursday: TimetableContent;
  friday: TimetableContent;
}

interface TimetableWholeStudent {
  type: string;
  timetable: TimetableWeek[];
}

export function TimetableDetailPage({ setShowTimetablePage }: TimetableDetailPageProps) {
  const [selectedLectureInfo, setSelectedLectureInfo] = useState<ResponseTimetableV3Dto | undefined>();

  const { timetableV3Student, error, loading } = useStudentTimetableDetail();

  const timesArray: number[] | undefined = timetableV3Student?.map((item: ResponseTimetableV3Dto) => item.time);
  const maxTime: number = timesArray ? Math.max(...timesArray) : 0;

  const todayNum = new Date().getDay();

  const lectureData = (day: number, time: number) => {
    const lectures = timetableV3Student?.filter((item) => item.day === day && item.time === time);

    // TODO lectures[0] 을 무조건 가져오는게 아니고, type = move 를 가져와야 함.
    return lectures ? lectures[0] : undefined;
  };

  const timeTableV3Click = (lecture: ResponseTimetableV3Dto | undefined) => {
    if (lecture && lecture?.type !== LectureType.UNKNOWN) {
      setSelectedLectureInfo(lecture);
    }
  };

  return (
    <div className="scroll-box fixed inset-0 z-100 h-screen w-full overflow-y-scroll bg-white">
      {loading && <Blank />}
      {error && <ErrorBlank />}
      <TopNavbar
        left={
          <div className="h-15">
            <BackButton className="h-15" onClick={() => setShowTimetablePage(false)} />
          </div>
        }
        title="시간표"
      />
      <div className="mt-8 flex w-full items-center justify-center">
        <table className="mx-auto min-w-3/4 text-center">
          <thead>
            <tr>
              <th className="" />
              <th className="" />
              <th className="min-w-max  rounded-l-xl bg-grey-9 py-4">월</th>
              <th className="min-w-max  bg-grey-9">화</th>
              <th className="min-w-max  bg-grey-9">수</th>
              <th className="min-w-max  bg-grey-9">목</th>
              <th className="min-w-max rounded-r-xl bg-grey-9">금</th>
            </tr>
            <tr>
              <th className="pb-4"></th>
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: maxTime }, (_, i) => (
              <tr key={i}>
                <td
                  className={`min-h-10.5 w-10 bg-grey-9 px-1 py-2
                  ${i === 0 ? 'rounded-t-xl' : ''} ${i === maxTime - 1 ? 'rounded-b-xl' : ''}`}
                >
                  {i + 1}
                </td>
                <div className="px-0.5"></div>
                {Array.from({ length: 5 }, (_, dayNum) => {
                  const lecture = lectureData(dayNum + 1, i + 1);
                  return (
                    <td
                      key={dayNum}
                      className={`border-grey-50 min-w-9.5 min-h-10.5 cursor-pointer border px-1 py-2 text-xs md:text-base 
                              ${
                                todayNum === dayNum + 1 ? 'bg-brand-1 bg-opacity-20' : 'bg-orange-0'
                              }                              
                              ${i === 0 && dayNum === 0 ? 'rounded-tl-xl border-l-0 border-t-0' : ''}  
                              ${i === 0 && dayNum === 4 ? 'rounded-tr-xl border-r-0 border-t-0' : ''}  
                              ${i === maxTime - 1 && dayNum === 0 ? 'rounded-bl-xl border-b-0 border-l-0' : ''}  
                              ${i === maxTime - 1 && dayNum === 4 ? 'rounded-br-xl border-b-0 border-r-0' : ''}  
                              ${i === 0 || i === maxTime - 1 ? 'border-b-0 border-t-0' : ''}  
                              ${dayNum === 0 || dayNum === 4 ? 'border-l-0 border-r-0' : ''}  
                              ${
                                lecture?.id !== undefined && selectedLectureInfo?.id === lecture?.id
                                  ? 'bg-yellow-200 text-red-500'
                                  : ''
                              }                                                     
                          `}
                      onClick={() => {
                        timeTableV3Click(lecture);
                      }}
                    >
                      {lecture?.subject}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedLectureInfo && (
        <div className="mt-8 flex w-full items-center justify-center">
          <div className="min-w-3/4 rounded-xl border border-grey-5 bg-white px-4">
            <Section>
              {/* <div className="absolute top-5 right-5 cursor-pointer p-3 text-red-500">
                X
              </div> */}

              <div>
                <div className="mb-2 flex text-base text-gray-500">
                  {dayOfKorWeek(selectedLectureInfo.day)}요일 {selectedLectureInfo.time + '교시'}
                </div>
                <div className="pb-1 text-lg font-bold">{selectedLectureInfo?.subject}</div>
              </div>

              <div className="flex items-center space-x-2">
                <UserIcon />
                <div className="text-lg">{selectedLectureInfo?.teacherName} 선생님</div>
              </div>

              <div className="flex items-center space-x-2">
                <KlassIcon />
                <div className="text-lg">{selectedLectureInfo?.room}</div>
              </div>
            </Section>
          </div>
        </div>
      )}
    </div>
  );
}
