import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useOutingsDelete, useOutingsDeny, useOutingsRequestDelete } from 'src/generated/endpoint';
import { errorType } from 'src/types';

export function useTeacherOutingDetail(id: number) {
  const { push } = useHistory();
  const [deny, setDeny] = useState(false);
  const [notApprovedReason, setNotApprovedReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [deleteAppeal, setDeleteAppeal] = useState(false);
  const [errM, setErrM] = useState('');

  const { mutate: denyOutingMutate, isLoading: isDenyOutingLoading } = useOutingsDeny({
    mutation: {
      onSuccess: () => {
        setDeny(false);
        setErrM('');
      },
      onError: (err) => {
        setErrM(err?.message);
      },
    },
  });

  // TODO: reason -> notApprovedReason 으로 변경필요
  const denyOuting = () => {
    denyOutingMutate({
      id,
      data: {
        reason: notApprovedReason,
      },
    });
  };

  const { mutate: requestDeleteOutingMutate, isLoading: isRequestDeleteOutingLoading } = useOutingsRequestDelete({
    mutation: {
      onSuccess: () => {
        setDeleteAppeal(false);
      },
    },
  });

  const requestDeleteOuting = () => {
    requestDeleteOutingMutate({
      id,
      data: {
        deleteReason,
      },
    });
  };

  const { mutate: deleteOutingMutate } = useOutingsDelete({
    mutation: {
      onSuccess: () => {
        alert('삭제되었습니다');
        push('/teacher/outing');
      },
      onError: (e) => {
        const errorMsg: errorType | undefined = e?.response?.data ? (e?.response?.data as errorType) : undefined;

        alert(errorMsg?.message || '일시적 오류 입니다. 잠시 후 다시 시도해주세요.');
      },
    },
  });
  const deleteOuting = () => {
    deleteOutingMutate({
      id,
    });
  };

  const isLoading = isDenyOutingLoading || isRequestDeleteOutingLoading;

  return {
    deny,
    setDeny,
    notApprovedReason,
    setNotApprovedReason,
    deleteReason,
    setDeleteReason,
    deleteAppeal,
    setDeleteAppeal,
    errM,
    setErrM,
    requestDeleteOuting,
    deleteOuting,
    denyOuting,
    isLoading,
  };
}
