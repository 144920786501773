import { useCodeGetCodesByCategory } from 'src/generated/endpoint';
import { Category } from 'src/generated/model';

export function useCodeByCategoryName(categoryName: Category) {
  const { data } = useCodeGetCodesByCategory(categoryName);

  return {
    categoryData: data,
  };
}
