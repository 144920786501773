/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Super School 백엔드
 * Super School API 상세
 * OpenAPI spec version: 0.1
 */

export type OutingTypeEnum = (typeof OutingTypeEnum)[keyof typeof OutingTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OutingTypeEnum = {
  조퇴: '조퇴',
  외출: '외출',
  확인: '확인',
} as const;
